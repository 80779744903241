export const roleOptions = [
  { label: "Admin", value: "admin" },
  { label: "User", value: "user" },
  { label: "Worker", value: "worker" },
];

export const statusOptions = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const statusMap = {
  0: "Quotation",
  1: "Accepted",
  2: "Rejected",
};

export const typeOptions = [
  { label: "Tie Band & Hooks", value: "hooks" },
  { label: "Pins", value: "pins" },
  { label: "Extra Poles", value: "extraPoles" },
  { label: "Adjustment / Discount", value: "discounts" },
  { label: "Others", value: "others" },
];

export const events = [
  "Done Poles Making",
  "Finished Sewing",
  "Order Delivered",
  "Order Dispatched",
];

export const enableOptions = [
  { label: 'Disable', value: false },
  { label: 'Enable', value: true }
];
