import React from "react";
import { currencyAmount } from "../../../helpers/TemplateHelper";

const PaymentSummaryView = ({
  order,
  totalReceivedPayment,
  remainingPayment,
  calculateTotal,
  calculateDiscountTotal,
}) => {
  const discountTotal = calculateDiscountTotal();

  let totalOrderPayment = calculateTotal();

  if (typeof totalOrderPayment === "number") {
    totalOrderPayment = `${currencyAmount(totalOrderPayment)} LKR`;
  }

  return (
    <div className="flex flex-column gap-1 flex-wrap summary w-full p-1">
      <h5 className="text-color-secondary">Payment Summary</h5>
      <div>
        <label className="block">Total Order Payment:</label>
        <label>{totalOrderPayment}</label>
      </div>
      <div>
        <label>Adjustment / Discount:</label>
        <label>{`${currencyAmount(discountTotal)} LKR`}</label>
      </div>
      <div>
        <label className="block">Total Received Payment:</label>
        <label>{`${currencyAmount(totalReceivedPayment)} LKR`}</label>
      </div>
      <div>
        <strong>Remaining Payment:</strong>
        <strong>{`${currencyAmount(remainingPayment)} LKR`}</strong>
      </div>
    </div>
  );
};

export default PaymentSummaryView;
