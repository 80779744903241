import React, { useState } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";

import { FormattedDate } from "../../../helpers/TemplateHelper";

import Event from "./Event";

const EventTable = ({ events, onChange, toastrRef, orderStatus }) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [select, setSelect] = useState(null);

  const handleDelete = (event) => {
    confirmDialog({
      message: "Are you sure you want to delete this event?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDelete(event),
    });
  };

  const confirmDelete = async (event) => {
    axios
      .delete(`/api/Event/${event.id}`)
      .then(() => {
        toastrRef.current.show({
          severity: "success",
          summary: "Event Deleted",
          detail: "Event has been successfully deleted.",
          life: 3000,
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error deleting event", error);
        toastrRef.current.show({
          severity: "error",
          summary: "Event Error",
          detail:
            "An error occurred while deleting the event. Please try again.",
          life: 3000,
        });
      });
  };
  const actionTemplate = (rowData) => {
    if (orderStatus === 1) {
      return (
        <div className="p-grid">
          <div className="p-col-6">
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-info mr-2"
              onClick={() => {
                setSelect(rowData);
                setDialogVisible(true);
              }}
            />
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger"
              onClick={() => handleDelete(rowData)}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <div className="pt-8">
        <h5 className="text-color-secondary">Timeline</h5>
        <DataTable
          value={events}
          tableStyle={{ minWidth: "960px" }}
          className="p-datatable-striped"
        >
          <Column style={{ width: "100px" }} header="" body={actionTemplate} />
          <Column field="eventName" header="Event" sortable />
          <Column
            style={{ width: "360px" }}
            field="remark"
            header="Remark"
            sortable
          />
          <Column
            style={{ width: "150px" }}
            field="updated"
            header="Updated"
            body={(row) => <FormattedDate date={row.updated} />}
            sortable
          />
          <Column
            style={{ width: "200px" }}
            field="updatedByName"
            header="UpdatedBy"
            sortable
          />
        </DataTable>
        <Event
          visible={dialogVisible}
          onCancel={() => setDialogVisible(false)}
          event={select}
          onEventAdded={() => onChange()}
          toastrRef={toastrRef}
        />
      </div>
    </div>
  );
};

export default EventTable;
