import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";

import { currencyAmount } from "../../../helpers/TemplateHelper";
import UnitPriceDialog from "../../components/UnitPriceDialog";

const StockViewer = () => {
  const toast = useRef(null);
  const dt = useRef(null);

  const [stocks, setStocks] = useState([]);
  const [unitPricesVisible, setUnitPricesVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [globalFilter, setGlobalFilter] = useState("");
  const [height, setHeight] = useState(window.innerHeight - 320);

  useEffect(() => {
    fetchStocks();

    const handleResize = () => {
      setHeight(window.innerHeight - 320);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchStocks = async () => {
    try {
      const response = await axios.get("/api/StockItems/stocks");
      setStocks(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching stocks: ", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch stocks. Please try again.",
        life: 3000,
      });
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const actionTemplate = (rowData) => {
    return (
      <Button
        severity="info"
        icon="pi pi-external-link"
        className="p-button-rounded p-button-primary mr-2"
        tooltip="View Unit Prices"
        tooltipOptions={{ event: "both" }}
        onClick={() => {
          setUnitPricesVisible(true);
          setSelectedItem(rowData);
        }}
      />
    );
  };

  return (
    <div className="card flex flex-column gap-4">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between align-items-center">
        <div>
          <h4 className="text-primary mb-1">Stock Overview</h4>
          <label className="text-secondary">View available stocks</label>
        </div>
        <div className="flex gap-2 align-items-center">
          <InputText
            className="p-inputtext-sm"
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
          <Button
            label="Refresh"
            severity="help"
            icon="pi pi-refresh"
            onClick={fetchStocks}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            onClick={exportCSV}
            className="p-button-success"
          />
        </div>
      </div>
      <DataTable
        ref={dt}
        value={stocks}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        globalFilter={globalFilter}
        scrollable
        scrollHeight={height}
      >
        <Column
          body={actionTemplate}
          header={""}
          style={{ width: "50px" }}
        ></Column>
        <Column
          header="Status"
          sortable
          body={(rowData) => (
            <Tag
              severity={
                rowData?.totalQuantity > rowData.reOrderLevel
                  ? "info"
                  : "warning"
              }
              className="mr-2"
              value={
                rowData?.totalQuantity > rowData.reOrderLevel
                  ? "Sufficient Stocks"
                  : "Insufficient Stocks"
              }
            />
          )}
        />
        <Column field="itemCode" header="Code" sortable />
        <Column field="itemCategory" header="Category" sortable />
        <Column field="itemName" header="Name" sortable />
        <Column
          field="totalQuantity"
          header="Quantity"
          sortable
          className="text-right"
          body={(rowData) =>
            `${currencyAmount(rowData.totalQuantity)} ${rowData.measureUnit}(s)`
          }
        />
      </DataTable>
      <Dialog
        visible={unitPricesVisible}
        onHide={() => setUnitPricesVisible(false)}
        header="Unit Prices"
        style={{ maxWidth: "700px", width: "80vw" }}
      >
        <UnitPriceDialog
          itemData={selectedItem}
          onCancel={() => setUnitPricesVisible(false)}
        />
      </Dialog>
    </div>
  );
};

export default StockViewer;
