import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import { DateOnlyFormat, currencyAmount } from "../../helpers/TemplateHelper";

const Dashboard = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState(null);

  const [orders, setOrders] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    axios
      .get("/api/orders/quotations")
      .then((res) => {
        const today = new Date().toISOString().split("T")[0];
        const todayOrders = res.data.filter((order) => {
          const orderDate = order.created.split("T")[0];
          return orderDate === today;
        });
        setOrders(todayOrders);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred while processing your request.",
            life: 3000,
          });
        }
      });
  };

  const calculateTotalAmount = (order) => {
    const {
      orderDetails: { subTotal, transportationCost, fixingCost },
    } = order;

    return subTotal + transportationCost + fixingCost;
  };

  return (
    <div className="card flex flex-column gap-4">
      <div className="flex flex-column align-items-center">
        <div>
          <img
            // eslint-disable-next-line no-undef
            src={require("../../images/logo.png")}
            alt="logo"
            className="logo"
            style={{ width: "150px" }}
          />
        </div>
        <h3>Back Office v.1.0.3 © 2023</h3>
        <strong></strong>
      </div>
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between">
        <div>
          <h4 className="text-primary mb-1">Today&apos;s Orders</h4>
          <label className="text-secondary">
            View all Today&apos;s Quotations
          </label>
        </div>
        <div className="flex flex-wrap justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
      <div>
        <DataTable value={orders} dataKey="id" globalFilter={globalFilter}>
          <Column
            header="No"
            body={(rowData, index) => {
              const orderIndex = orders.findIndex(
                (order) => order.id === rowData.id
              );
              return orderIndex !== -1 ? orderIndex + 1 : null;
            }}
          ></Column>
          <Column
            sortable
            field="id"
            header="ID"
            body={(row) => row.id.toString().padStart(8, "0")}
          ></Column>
          <Column
            sortable
            field="customerDetails.firstName"
            header="First Name"
            style={{ minWidth: "120px" }}
          ></Column>
          <Column
            sortable
            field="customerDetails.lastName"
            header="Last Name"
            style={{ minWidth: "120px" }}
          ></Column>
          <Column
            sortable
            field="customerDetails.telephone"
            header="Telephone"
          ></Column>
          <Column
            sortable
            field="created"
            header="Created"
            body={(row) => <DateOnlyFormat date={row.created} />}
          ></Column>
          <Column
            className="text-right"
            sortable
            header="Sets"
            field="orderDetails.totalQty"
          ></Column>
          <Column
            className="text-right"
            sortable
            header="Total Pleats"
            field="orderDetails.totalPleats"
          ></Column>
          <Column
            className="text-right"
            sortable
            header="Amount"
            headerStyle={{ textAlign: "right" }}
            style={{ minWidth: "120px" }}
            body={(rowData) =>
              `${currencyAmount(calculateTotalAmount(rowData))} LKR`
            }
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Dashboard;
