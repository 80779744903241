import React from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { currencyAmount } from "../../helpers/TemplateHelper";

const UnitPriceDialog = ({ itemData, onHide }) => {
  return (
    <React.Fragment>
      <div className="flex gap-8 mb-4">
        <div>
          <label className="block">Item Code</label>
          <strong>{itemData.itemCode}</strong>
        </div>
        <div>
          <label className="block">Item Category</label>
          <strong>{itemData.itemCategory}</strong>
        </div>
        <div>
          <label className="block">Item Name</label>
          <strong>{itemData.itemName}</strong>
        </div>
        <div>
          <label className="block">Total Quantity</label>
          <strong>{`${currencyAmount(itemData.totalQuantity)} ${
            itemData.measureUnit
          }(s)`}</strong>
        </div>
      </div>
      <DataTable value={itemData?.stockItems} paginator rows={5}>
        <Column
          field="unitPrice"
          header="Unit Price"
          className="text-right"
          body={(rowData) => `${currencyAmount(rowData.unitPrice)} LKR`}
        />
        <Column
          field="quantity"
          header="Quantity"
          className="text-right"
          body={(rowData) =>
            `${currencyAmount(rowData.quantity)} ${itemData.measureUnit}(s)`
          }
        />
        <Column
          field="updated"
          header="Updated Date"
          body={(rowData) => new Date(rowData.updated).toLocaleDateString()}
        />
      </DataTable>
    </React.Fragment>
  );
};

export default UnitPriceDialog;
