import React, { useState, useEffect } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";

import { typeOptions } from "../../../const";

const formData = {
  id: 0,
  orderId: 0,
  amount: null,
  type: "",
  remark: "",
};

const AddOnDialog = ({
  visible,
  onCancel,
  orderId,
  toastrRef,
  onAddOnAdded,
  addOn,
}) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm(addOn || { ...formData });
  }, [visible, addOn]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.orderId = orderId;

    axios
      .post("/api/addOns", form)
      .then((response) => {
        toastrRef.current.show({
          severity: "success",
          summary: "AddOn Added / Updated",
          detail: "AddOn has been successfully added / updated.",
          life: 3000,
        });
        onAddOnAdded(response.data);
        onCancel();
      })
      .catch((error) => {
        console.error("Error adding / updating addon:", error);
        toastrRef.current.show({
          severity: "error",
          summary: "AddOn Error",
          detail:
            "An error occurred while adding / updating the addon. Please try again.",
          life: 3000,
        });
      });
  };

  return (
    <Dialog
      header="Add / Edit Add-Ons"
      visible={visible}
      onHide={onCancel}
      modal
      style={{ width: "400px", minHeight: "fit-content" }}
    >
      <form onSubmit={handleSubmit}>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="Type">Type</label>
            <Dropdown
              id="type"
              value={form.type}
              name="Type"
              required
              onChange={(e) => setForm({ ...form, type: e.target.value })}
              options={typeOptions}
              autoComplete="off"
              placeholder="Select a type"
            />
          </div>
          <div className="p-field">
            <label htmlFor="Amount">Amount</label>
            <InputNumber
              id="Amount"
              value={form.amount}
              required
              name="Amount"
              onValueChange={(e) => setForm({ ...form, amount: e.value })}
              minFractionDigits={2}
              prefix="LKR "
              locale="en-LK"
            />
          </div>
          <div className="p-field">
            <label htmlFor="Remark">Remark</label>
            <InputTextarea
              autoResize
              value={form.remark}
              onChange={(e) => setForm({ ...form, remark: e.target.value })}
              rows={5}
              cols={30}
            />
          </div>
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button label="Submit" type="submit" />
        </div>
      </form>
    </Dialog>
  );
};

export default AddOnDialog;
