import { React, useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";
import axios from "axios";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import { DateOnlyFormat, currencyAmount } from "../../helpers/TemplateHelper";

const RejectOrders = () => {
  const toast = useRef(null);

  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [orders, setOrders] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [height, setHeight] = useState(0);

  const dt = useRef(null);

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight;
      const specificAmount = 370;
      setHeight(vh - specificAmount);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchOrders = () => {
    const url = `/api/orders/completed?fromDate=${fromDate.toLocaleDateString()}&toDate=${toDate.toLocaleDateString()}`;
    axios
      .get(url)
      .then((res) => {
        const ordersWithAddonCost = res.data.map((order) => {
          const addonCost = calculateAddonCost(order.addOns);
          const discountTotal = calculateDiscountTotal(order.addOns);
          const totalAddonCostWithDiscount = addonCost - discountTotal;
          return { ...order, discountTotal, totalAddonCostWithDiscount };
        });
        setOrders(ordersWithAddonCost);
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while processing your request.",
          life: 3000,
        });
      });
  };

  const calculateAddonCost = (addOns) => {
    let addonCost = 0;

    if (addOns) {
      addOns.forEach((addOn) => {
        addonCost += addOn.amount;
      });
    }

    return addonCost;
  };

  const calculateDiscountTotal = (addOns) => {
    let discountTotal = 0;

    if (addOns) {
      addOns.forEach((addOn) => {
        if (addOn.type === "discounts") {
          discountTotal += addOn.amount;
        }
      });
    }

    return discountTotal;
  };

  const calculateTotalAmount = (order) => {
    const {
      orderDetails: { subTotal, transportationCost, fixingCost },
      totalAddonCostWithDiscount,
    } = order;

    return (
      subTotal + transportationCost + fixingCost + totalAddonCostWithDiscount
    );
  };

  const calculateTotalPayments = (order) => {
    if (order && order.payments && order.payments.length > 0) {
      const totalReceivedPayment = order.payments.reduce(
        (total, payment) => total + payment.amount,
        0
      );
      return totalReceivedPayment;
    }
    return 0;
  };

  const calculateRemainingPayment = (order) => {
    const totalAmount = calculateTotalAmount(order);
    const { discountTotal } = order;
    const totalPayments = calculateTotalPayments(order);

    const remainingPayment = totalAmount - (discountTotal + totalPayments);
    return remainingPayment > 0 ? remainingPayment : 0;
  };

  const handleConfirmReverse = (orderId) => {
    confirmDialog({
      message: "Are you sure you want to reverse this order?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmReverse(orderId),
    });
  };

  const confirmReverse = (orderId) => {
    axios
      .post(`/api/orders/reverse/${orderId}`)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Order Reversed",
          detail: "Order has been successfully reversed.",
          life: 3000,
        });

        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );
        fetchOrders();
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while accepting the order.",
          life: 3000,
        });
      });
  };

  const viewButtonTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/pages/order/${rowData.id}`}>
          <Button
            icon="pi pi-external-link"
            className="p-button-rounded p-button-info mr-2"
            tooltip="View"
            tooltipOptions={{ event: "both" }}
          ></Button>
        </Link>
        <Button
          icon="pi pi-arrow-left"
          className="p-button-rounded p-button-warning mr-2"
          tooltip="Reverse Order"
          tooltipOptions={{ event: "both" }}
          onClick={() => handleConfirmReverse(rowData.id)}
        ></Button>
      </div>
    );
  };

  const cols = [
    {
      header: "",
      body: viewButtonTemplate,
    },
    {
      field: "id",
      header: "ID",
      body: (row) => row.id.toString().padStart(8, "0"),
    },
    {
      header: "Bill No",
      body: (rowData) =>
        rowData.payments && rowData.payments.length > 0
          ? rowData.payments[0].referenceNo
          : "",
    },
    {
      field: "customerDetails.fullName",
      header: "Customer Name",
    },
    {
      field: "customerDetails.telephone",
      header: "Telephone",
    },
    {
      field: "created",
      header: "Created",
      body: (row) => <DateOnlyFormat date={row.created} />,
    },
    {
      className: "text-right",
      header: "Sets",
      field: "orderDetails.totalQty",
    },
    {
      className: "text-right",
      header: "Total Pleats",
      field: "orderDetails.totalPleats",
    },
    {
      className: "text-right",
      header: "Amount",
      headerStyle: { textAlign: "right" },
      body: (rowData) => `${currencyAmount(calculateTotalAmount(rowData))}`,
    },
    {
      className: "text-right",
      header: "Discount",
      headerStyle: { textAlign: "right" },
      body: (rowData) => `${currencyAmount(rowData.discountTotal)}`,
    },
    {
      className: "text-right",
      header: "Addons",
      headerStyle: { textAlign: "right" },
      body: (rowData) =>
        `${currencyAmount(rowData.totalAddonCostWithDiscount)}`,
    },
    {
      className: "text-right",
      header: "Payment",
      headerStyle: { textAlign: "right" },
      body: (rowData) => `${currencyAmount(calculateTotalPayments(rowData))}`,
    },
    {
      className: "text-right",
      header: "Balance",
      headerStyle: { textAlign: "right" },
      body: (rowData) =>
        `${currencyAmount(calculateRemainingPayment(rowData))}`,
    },
  ];

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  return (
    <div className="card flex flex-column gap-4">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between">
        <div>
          <h4 className="text-primary mb-1">Completed Orders</h4>
          <label className="text-secondary">View completed orders</label>
        </div>
        <div className="flex gap-2 align-items-center flex-wrap">
          <span className="filter-label">From Date : </span>
          <Calendar
            value={fromDate}
            onChange={(e) => setFromDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <span className="filter-label">To Date : </span>
          <Calendar
            value={toDate}
            onChange={(e) => setToDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <Button
            style={{ height: "33px" }}
            label="Refresh"
            icon="pi pi-refresh"
            severity="help"
            onClick={fetchOrders}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            severity="success"
            onClick={() => exportCSV(false)}
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
        </span>
      </div>
      <div>
        <DataTable
          ref={dt}
          value={orders}
          dataKey="id"
          paginator
          rows={25}
          stripedRows
          rowsPerPageOptions={[25, 50, 100]}
          globalFilter={globalFilter}
          scrollable
          scrollHeight={height}
        >
          {cols.map((col, index) => (
            <Column key={index} {...col} sortable></Column>
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default RejectOrders;
