import { React, useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";
import axios from "axios";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import { DateOnlyFormat, currencyAmount } from "../../helpers/TemplateHelper";

const RejectOrders = () => {
  const toast = useRef(null);

  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [orders, setOrders] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [height, setHeight] = useState(0);

  const dt = useRef(null);

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight;
      const specificAmount = 370;
      setHeight(vh - specificAmount);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchOrders = () => {
    const url = `/api/orders/rejected?fromDate=${fromDate.toLocaleDateString()}&toDate=${toDate.toLocaleDateString()}`;
    axios
      .get(url)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while processing your request.",
          life: 3000,
        });
      });
  };

  const handleConfirmAccept = (orderId) => {
    confirmDialog({
      message: "Are you sure you want to accept this order?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmAccept(orderId),
    });
  };

  const confirmAccept = (orderId) => {
    axios
      .post(`/api/orders/accept/${orderId}`)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Order Accepted",
          detail: "Order has been successfully accepted.",
          life: 3000,
        });

        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );
        fetchOrders();
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while accepting the order.",
          life: 3000,
        });
      });
  };

  const calculateTotalAmount = (order) => {
    const {
      orderDetails: { subTotal, transportationCost, fixingCost },
    } = order;

    return subTotal + transportationCost + fixingCost;
  };

  const viewButtonTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/pages/order/${rowData.id}`}>
          <Button
            icon="pi pi-external-link"
            className="p-button-rounded p-button-info mr-2"
            tooltip="View"
            tooltipOptions={{ event: "both" }}
          ></Button>
        </Link>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-success mr-2"
          tooltip="Accept"
          tooltipOptions={{ event: "both" }}
          onClick={() => handleConfirmAccept(rowData.id)}
        ></Button>
      </div>
    );
  };

  const cols = [
    {
      header: "",
      body: viewButtonTemplate,
    },
    {
      field: "id",
      header: "ID",
      body: (row) => row.id.toString().padStart(8, "0"),
    },
    {
      field: "customerDetails.fullName",
      header: "Customer Name",
      style: { minWidth: "120px" },
    },
    {
      field: "customerDetails.telephone",
      header: "Telephone",
    },
    {
      field: "created",
      header: "Created",
      style: { minWidth: "120px" },
      body: (row) => <DateOnlyFormat date={row.created} />,
    },
    {
      className: "text-right",
      header: "Sets",
      field: "orderDetails.totalQty",
    },
    {
      className: "text-right",
      header: "Total Pleats",
      field: "orderDetails.totalPleats",
    },
    {
      className: "text-right",
      header: "Amount",
      headerStyle: { textAlign: "right" },
      style: { minWidth: "120px" },
      body: (rowData) => `${currencyAmount(calculateTotalAmount(rowData))} LKR`,
    },
  ];

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  return (
    <div className="card flex flex-column gap-4">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between">
        <div>
          <h4 className="text-primary mb-1">Rejected Orders</h4>
          <label className="text-secondary">View rejected orders</label>
        </div>
        <div className="flex gap-2 align-items-center flex-wrap">
          <span className="filter-label">From Date : </span>
          <Calendar
            value={fromDate}
            onChange={(e) => setFromDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <span className="filter-label">To Date : </span>
          <Calendar
            value={toDate}
            onChange={(e) => setToDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <Button
            label="Refresh"
            icon="pi pi-refresh"
            severity="help"
            onClick={fetchOrders}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            severity="success"
            onClick={() => exportCSV(false)}
          />
        </div>
      </div>
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm"
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
        </span>
      </div>
      <div>
        <DataTable
          ref={dt}
          value={orders}
          dataKey="id"
          paginator
          rows={25}
          stripedRows
          rowsPerPageOptions={[25, 50, 100]}
          globalFilter={globalFilter}
          scrollable
          scrollHeight={height}
        >
          {cols.map((col, index) => (
            <Column key={index} {...col} sortable></Column>
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default RejectOrders;
