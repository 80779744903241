import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import { roleOptions, statusOptions } from "../../const";

const EditUser = ({
  addNewItem,
  initialValues,
  onCancel,
  isAddDialogOpen,
  isEditDialogOpen,
  setShowUpdate,
}) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [branchOptions, setBranchOptions] = useState([]);

  const [isWorkerRole, setIsWorkerRole] = useState(false);

  const toast = useRef(null);

  useEffect(() => {
    setFormData(initialValues || {});
    if (formData.status == 1) {
      formData.status = "ACTIVE";
    } else if (formData.status == 0) {
      formData.status = "INACTIVE";
    }
  }, [initialValues]);

  useEffect(() => {
    axios
      .get("/api/keyValuePairs/getByKey/branch")
      .then((response) => {
        const branchOptions = response.data.map((branch) => ({
          label: branch.value,
          value: branch.value,
        }));
        setBranchOptions(branchOptions);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Branch Error",
          detail: "An error occurred while fetching branch. Please try again.",
          life: 3000,
        });
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "role") {
      setIsWorkerRole(value != "worker");
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isAddDialogOpen) {
      const errors = validateForm(formData);
      if (Object.keys(errors).length === 0) {
        addNewItem(formData);
        setFormData({});
        setFormErrors({});
      } else {
        setFormErrors(errors);
      }
    }
    if (isEditDialogOpen) {
      const errors = validateForm(formData);
      if (Object.keys(errors).length === 0) {
        handleUpdateItem(formData);
        setFormData(formData);

        setFormErrors({});
      } else {
        setFormErrors(errors);
      }
      setShowUpdate(false);
    }
  };

  const handleUpdateItem = async (updatedItem) => {
    try {
      await axios.put(`/api/User/${updatedItem.id}`, updatedItem);
      const updatedData = data.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item
      );
      setData(updatedData);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const validateForm = (formData) => {
    const errors = {};

    if (!formData.firstName) {
      errors.firstName = "First Name is required.";
    }

    if (!formData.lastName) {
      errors.lastName = "Last Name is required.";
    }

    if (!formData.role) {
      errors.role = "Role is required.";
    }

    if (!formData.branch) {
      errors.branch = "Branch is required.";
    }

    if (!formData.address) {
      errors.address = "Address is required.";
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone Number is required.";
    } else if (!/^\d+$/u.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone Number must contain only digits.";
    }

    if (!formData.email) {
      errors.email = "E-mail is required.";
    } else if (!/^\S+@\S+\.\S+$/u.test(formData.email)) {
      errors.email = "Invalid E-mail format.";
    }

    if (!formData.status) {
      errors.status = "Status is required.";
    }

    return errors;
  };
  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <form onSubmit={handleSubmit}>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="firstName">First Name</label>
            <InputText
              id="firstName"
              name="firstName"
              value={formData.firstName || ""}
              onChange={handleChange}
            />
            {formErrors.firstName && (
              <small className="p-error">{formErrors.firstName}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="lastName">Last Name</label>
            <InputText
              id="lastName"
              name="lastName"
              value={formData.lastName || ""}
              onChange={handleChange}
            />
            {formErrors.lastName && (
              <small className="p-error">{formErrors.lastName}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="role">Role</label>
            <Dropdown
              id="role"
              name="role"
              options={roleOptions}
              value={formData.role || ""}
              onChange={handleChange}
              optionLabel="label"
              placeholder="Select a role"
            />
            {formErrors.role && (
              <small className="p-error">{formErrors.role}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="branch">Branch</label>
            <Dropdown
              id="branchId"
              name="branch"
              options={branchOptions}
              value={formData.branch || ""}
              onChange={handleChange}
              optionLabel="label"
              placeholder="Select a branch"
            />
            {formErrors.branch && (
              <small className="p-error">{formErrors.branch}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="address">Address</label>
            <InputText
              id="address"
              name="address"
              value={formData.address || ""}
              onChange={handleChange}
            />
            {formErrors.address && (
              <small className="p-error">{formErrors.address}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="phoneNumber">Phone Number</label>
            <InputText
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber || ""}
              onChange={handleChange}
            />
            {formErrors.phoneNumber && (
              <small className="p-error">{formErrors.phoneNumber}</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="email">E-mail</label>
            <InputText
              id="email"
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
            />
            {formErrors.email && (
              <small className="p-error">{formErrors.email}</small>
            )}
          </div>

          {isWorkerRole && (
            <>
              <div className="p-field">
                <label htmlFor="username">User Name</label>
                <InputText
                  id="username"
                  name="username"
                  value={formData.username || ""}
                  onChange={handleChange}
                />
                {formErrors.username && (
                  <small className="p-error">{formErrors.username}</small>
                )}
              </div>
              <div className="p-field">
                <label htmlFor="password">Password</label>
                <InputText
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password || ""}
                  onChange={handleChange}
                />
                {formErrors.password && (
                  <small className="p-error">{formErrors.password}</small>
                )}
              </div>
            </>
          )}

          <div className="p-field">
            <label htmlFor="status">Status</label>
            <Dropdown
              id="status"
              name="status"
              options={statusOptions}
              value={formData.status || ""}
              onChange={handleChange}
              optionLabel="label"
              placeholder={`${formData.status || "Selected a Status"} `}
            />
            {formErrors.status && (
              <small className="p-error">{formErrors.status}</small>
            )}
          </div>
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </div>
  );
};

export default EditUser;
