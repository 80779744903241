import React, { useEffect, useState } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { currencyAmount } from "../../../helpers/TemplateHelper";

const formData = {
  id: 0,
  orderId: 0,
  userId: 0,
  type: null,
  measureUnit: null,
  unitPrice: null,
  value: "",
};

const ContributionDialog = ({
  visible,
  onCancel,
  orderId,
  toastrRef,
  onContributionAdded,
  contribution,
}) => {
  const [typeOptions, setTypeOptions] = useState([]);
  const [userIdOptions, setUserIdOptions] = useState([]);
  const [form, setForm] = useState({ ...formData });

  useEffect(() => {
    setForm(contribution || { ...formData });
  }, [visible, contribution]);

  useEffect(() => {
    axios
      .get("/api/ContributionTypes/enable")
      .then((response) => {
        const typeOptions = response.data.map((contributionType) => ({
          label: contributionType.type,
          value: contributionType.type,
          measureUnit: contributionType.measureUnit,
          unitPrice: contributionType.unitPrice,
        }));
        setTypeOptions(typeOptions);
      })
      .catch((error) => {
        console.error("Error fetching contribution types:", error);
        toastrRef.current.show({
          severity: "error",
          summary: "Contribution Type Error",
          detail:
            "An error occurred while fetching contribution types. Please try again.",
          life: 3000,
        });
      });

    axios
      .get("/api/User/active")
      .then((response) => {
        const userIdOptions = response.data.map((user) => ({
          label: `${user.id} - ${user.firstName} ${user.lastName}`,
          value: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        }));
        setUserIdOptions(userIdOptions);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
        toastrRef.current.show({
          severity: "error",
          summary: "Contribution User Error",
          detail:
            "An error occurred while fetching contribution user. Please try again.",
          life: 3000,
        });
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.orderId = orderId;

    axios
      .post("/api/Contributions", form)
      .then((response) => {
        toastrRef.current.show({
          severity: "success",
          summary: "Contribution Added / Updated Successfully",
          detail: "Contribution has been successfully added / updated.",
          life: 3000,
        });
        onContributionAdded(response.data);
        onCancel();
      })
      .catch((error) => {
        console.error("Error adding / updating contribution:", error);
        toastrRef.current.show({
          severity: "error",
          summary: "Contribution Error",
          detail:
            "An error occurred while adding / updating the contribution. Please try again.",
          life: 3000,
        });
      });
  };

  const handleContributionTypeChange = (e) => {
    const selectedType = typeOptions.find(
      (typeOption) => typeOption.value === e.value
    );
    setForm({
      ...form,
      type: e.value,
      measureUnit: selectedType.measureUnit,
      unitPrice: selectedType.unitPrice,
    });
  };

  return (
    <Dialog
      header="Add / Edit Contribution"
      visible={visible}
      onHide={onCancel}
      style={{ width: "400px", minHeight: "fit-content" }}
    >
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="userId">Employee *</label>
          <Dropdown
            id="userId"
            options={userIdOptions}
            value={form.userId}
            onChange={(e) => setForm({ ...form, userId: e.value })}
            placeholder="Select Employee"
            required
          />
        </div>
        <div className="p-field">
          <label htmlFor="contributionType">Contribution Type *</label>
          <Dropdown
            id="contributionType"
            value={form.type}
            options={typeOptions}
            onChange={handleContributionTypeChange}
            optionLabel="label"
            optionValue="value"
            placeholder="Select Contribution Type"
            required
          />
        </div>
        {form.type && (
          <div className="flex justify-content-between mt-1 mb-3">
            <strong>Measure Unit: {form.measureUnit}</strong>
            <strong>Unit Price: {currencyAmount(form.unitPrice)} LKR </strong>
          </div>
        )}
        <div className="p-field">
          <label htmlFor="contributionValue">Contribution Value *</label>
          <InputText
            id="contributionValue"
            value={form.value}
            type="number"
            onChange={(e) => setForm({ ...form, value: e.target.value })}
            required
            autoComplete="off"
          />
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </Dialog>
  );
};

export default ContributionDialog;
