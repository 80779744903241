import React, { useState } from "react";

import axios from "axios";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

import AddOnDialog from "./AddOnDialog";
import { currencyAmount, FormattedDate } from "../../../helpers/TemplateHelper";

const AddOnTable = ({ addons, onChange, toastrRef, orderStatus }) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [select, setSelect] = useState(null);

  const handleDelete = (addOn) => {
    confirmDialog({
      message: "Are you sure you want to delete this addOn?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDelete(addOn),
    });
  };

  const confirmDelete = (addOn) => {
    axios
      .delete(`/api/addOns/${addOn.id}`)
      .then((response) => {
        toastrRef.current.show({
          severity: "success",
          summary: "AddOn Deleted",
          detail: "AddOn has been successfully deleted.",
          life: 3000,
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error deleting addon:", error);
        toastrRef.current.show({
          severity: "error",
          summary: "AddOn Error",
          detail:
            "An error occurred while deleting the AddOn. Please try again.",
          life: 3000,
        });
      });
  };

  const actionTemplate = (rowData) => {
    if (orderStatus === 1) {
      return (
        <div className="p-grid">
          <div className="p-col-6">
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-info mr-2"
              onClick={() => {
                setSelect(rowData);
                setDialogVisible(true);
              }}
            />
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger"
              onClick={() => handleDelete(rowData)}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const amountTemplate = (rowData) => {
    return (
      <div style={{ textAlign: "right" }}>
        {currencyAmount(rowData.amount)} LKR
      </div>
    );
  };

  return (
    <div>
      <div className="pt-8">
        <h5 className="text-color-secondary">AddOns</h5>
        <DataTable
          value={addons}
          tableStyle={{ minWidth: "960px" }}
          className="p-datatable-striped"
        >
          <Column style={{ width: "100px" }} header="" body={actionTemplate} />
          <Column field="type" header="Type" sortable />
          <Column
            style={{ width: "310px" }}
            field="remark"
            header="Remark"
            sortable
          />
          <Column
            style={{ width: "200px" }}
            field="amount"
            header="Amount"
            sortable
            bodyStyle={{ textAlign: "right" }}
            body={amountTemplate}
          />
          <Column
            style={{ width: "150px" }}
            field="updated"
            header="Updated"
            sortable
            body={(row) => <FormattedDate date={row.updated} />}
          />
          <Column
            style={{ width: "200px" }}
            field="updatedByName"
            header="UpdatedBy"
            sortable
            headerStyle={{ textAlign: "right" }}
          />
        </DataTable>
        <AddOnDialog
          visible={dialogVisible}
          onCancel={() => setDialogVisible(false)}
          addOn={select}
          onAddOnAdded={() => onChange()}
          toastrRef={toastrRef}
        />
      </div>
    </div>
  );
};

export default AddOnTable;
