import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import axios from "axios";

import EditUser from "./EditUser";

const UserPage = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/api/User/${id}`);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [id, showUpdateDialog]);

  const handleShowUpdateDialog = () => {
    setShowUpdateDialog(true);
  };

  const handleUpdateUserData = async (updatedData) => {
    try {
      await axios.put(`/api/User/${id}`, updatedData);
      setUserData(updatedData);
      setShowUpdateDialog(false);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  return (
    <div className="container">
      <div className="user-profile">
        <div className="user-details">
          <img
            src={require("../../images/user.png")}
            alt="user iamge"
            className="user-image"
          />
          <div className="basic-details">
            <h2 className="mb-2 text-primary">
              {userData?.firstName} {userData?.lastName}
            </h2>
            <h6 className="mt-0">{userData?.email}</h6>
            <h6 className="mt-0">{userData?.phoneNumber}</h6>
            <div className="m-0 mt-2">
              <Tag
                severity="info"
                className="mr-2"
                icon="pi pi-user"
                value={userData?.role?.toUpperCase()}
              ></Tag>
              <Tag
                severity={userData?.status === 1 ? "success" : "danger"}
                icon={userData?.status === 1 ? "pi pi-check" : "pi pi-lock"}
                value={userData?.status === 1 ? "Active" : "Inactive"}
              />
            </div>
          </div>
        </div>
        <div className="advanced-details">
          <Button
            label="Update User"
            icon="pi pi-pencil"
            onClick={handleShowUpdateDialog}
          />
        </div>
      </div>
      <hr></hr>
      <Dialog
        visible={showUpdateDialog}
        onHide={() => setShowUpdateDialog(false)}
        header="Update User"
        style={{ width: "450px" }}
      >
        {userData && (
          <EditUser
            id={id}
            isEditDialogOpen={showUpdateDialog}
            setShowUpdate={setShowUpdateDialog}
            addNewItem={handleUpdateUserData}
            initialValues={userData}
            onCancel={() => setShowUpdateDialog(false)}
          />
        )}
      </Dialog>
    </div>
  );
};

export default UserPage;
