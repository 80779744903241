import React, { useEffect, useState } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";

const formData = {
  id: 0,
  userId: 0,
  amount: null,
  remark: "",
  updated: new Date(),
};

const UserPaymentDialog = ({ onCancel, onSubmit, toast }) => {
  const [form, setForm] = useState({ ...formData });
  const [userIdOptions, setUserIdOptions] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    axios
      .get("/api/User/active")
      .then((response) => {
        const userIdOptions = response.data.map((user) => ({
          label: `${user.id} - ${user.firstName} ${user.lastName}`,
          value: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        }));
        setUserIdOptions(userIdOptions);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "User Error",
          detail: "An error occurred while fetching user. Please try again.",
          life: 3000,
        });
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.userId) newErrors.userId = "Employee is required";
    if (!form.amount) newErrors.amount = "Amount is required";
    if (!form.updated) newErrors.updated = "Updated date is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const result = await axios.post("api/UserPayments", {
          ...form,
          updated: form.updated.toISOString(),
        });

        if (result.data?.id) {
          toast.current.show({
            severity: "success",
            summary: "User Payment Added",
            detail: "User payment has been successfully added.",
            life: 3000,
          });
          setForm({ ...formData });
          onSubmit();
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "User payment Error",
          detail:
            "An error occurred while adding user payment. Please try again.",
          life: 3000,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-field">
        <label htmlFor="userId">Employee *</label>
        <Dropdown
          id="userId"
          name="userId"
          options={userIdOptions}
          value={form.userId || ""}
          onChange={handleChange}
          placeholder="Select Employee"
        />
        {errors.userId && <label className="p-error">{errors.userId}</label>}
      </div>
      <div className="p-field">
        <label htmlFor="amount">Amount *</label>
        <InputNumber
          inputId="amount"
          name="amount"
          value={form?.amount || null}
          onValueChange={(e) => handleChange(e)}
          minFractionDigits={2}
          prefix="LKR "
          locale="en-LK"
          autoComplete="off"
        />
        {errors.amount && <label className="p-error">{errors.amount}</label>}
      </div>
      <div className="p-field">
        <label htmlFor="remark">Remark</label>
        <InputTextarea
          id="remark"
          name="remark"
          rows={4}
          value={form.remark || ""}
          onChange={handleChange}
          autoComplete="off"
        />
      </div>
      <div className="p-field">
        <label htmlFor="updated">Updated *</label>
        <Calendar
          id="updated"
          name="updated"
          value={form.updated}
          onChange={(e) => setForm({ ...form, updated: e.value })}
          showIcon
          showTime
          hourFormat="24"
          dateFormat="yy-mm-dd"
        />
        {errors.updated && <label className="p-error">{errors.updated}</label>}
      </div>
      <div className="flex justify-content-end">
        <Button
          type="button"
          label="Cancel"
          className="p-button-text mr-2"
          onClick={onCancel}
        />
        <Button type="submit" label="Submit" />
      </div>
    </form>
  );
};

export default UserPaymentDialog;
