import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tag } from "primereact/tag";

import { classNames } from "primereact/utils";
import { LayoutContext } from "./context/layoutcontext";

const AppTopbar = forwardRef((props, ref) => {
  const op = useRef(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { layoutState, onMenuToggle, showProfileSidebar } =
    useContext(LayoutContext);
  const menubuttonRef = useRef(null);
  const topbarmenuRef = useRef(null);
  const topbarmenubuttonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
    topbarmenu: topbarmenuRef.current,
    topbarmenubutton: topbarmenubuttonRef.current,
  }));

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const confirmDelete = () => {
    setShowDeleteConfirmation(false);
    localStorage.clear();
    window.location.reload();
  };

  const logoutClick = () => {
    setShowDeleteConfirmation(true);
  };

  const token = localStorage.getItem("token");
  var tok = "";
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    tok = JSON.parse(jsonPayload);
  }
  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img src={require("../images/logo.png")} alt="logo" className="logo" />
        Back Office
      </Link>

      <button
        ref={menubuttonRef}
        type="button"
        className="p-link layout-menu-button layout-topbar-button"
        onClick={onMenuToggle}
      >
        <i className="pi pi-bars" />
      </button>

      <button
        ref={topbarmenubuttonRef}
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={showProfileSidebar}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <div
        ref={topbarmenuRef}
        className={classNames("layout-topbar-menu", {
          "layout-topbar-menu-mobile-active": layoutState.profileSidebarVisible,
        })}
      >
        <div className="flex flex-row flex-wrap mr-2 justify-content-between">
          <div>
            <h6 className="mt-3 text-secondary">
              {tok?.firstName} {tok?.lastName}
            </h6>
          </div>

          <Button
            type="button"
            className="p-button-rounded mt-1 ml-2"
            onClick={(e) => op.current.toggle(e)}
            icon="pi pi-user"
          ></Button>
        </div>

        <OverlayPanel ref={op}>
          <div
            className="user-details"
            style={{ width: "230px", height: "160px" }}
          >
            <div className="flex flex-row flex-wrap justify-content-between">
              <img
                src={require("../images/user.png")}
                alt="user image"
                className="user-image"
                style={{ width: "40px" }}
              />
              <Tag
                severity="info"
                style={{ height: "1.5rem" }}
                icon="pi pi-user"
                value={tok?.role?.toUpperCase()}
              ></Tag>
            </div>

            <div className="basic-details flex flex-column flex-wrap">
              <h6 className="mb-2 text-primary text-lg">
                {tok?.firstName} {tok?.lastName}
              </h6>

              <div>
                <strong className="block text-base">Email: {tok?.email}</strong>
              </div>
              <div>
                <strong className="block text-base">
                  Contact No: {tok?.phoneNumber}
                </strong>
              </div>

              <div className="flex justify-content-end mt-5">
                <Button
                  icon="pi pi-sign-out"
                  label="Sign Out"
                  className="p-button p-button-primary h-2rem text-base"
                  onClick={confirmDelete}
                />
              </div>
            </div>
          </div>
        </OverlayPanel>
        <Link to="/documentation"></Link>
      </div>
    </div>
  );
});

export default AppTopbar;
