import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "primereact/resources/themes/mdc-light-deeppurple/theme.css";

import "primereact/resources/primereact.min.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import Login from "./Login";
import App from "./App";

import axios from "axios";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const token = localStorage.getItem("token");

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers.common["Accept"] = `application/json`;
}

root.render(
  <BrowserRouter basename={baseUrl}>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/pages/*" element={<App />} />
      <Route path="/" element={<Navigate replace to="/pages/dashboard" />} />
    </Routes>
  </BrowserRouter>
);

serviceWorkerRegistration.unregister();
