import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

const formData = {
  id: 0,
  content: "",
  configuration: {
    BackgroundColor: "#feff9c",
  },
};

const UpsertTodo = ({ note, onCancel, onSubmit }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedColor, setColor] = useState("#feff9c");
  const toast = useRef(null);

  const colors = ["#feff9c", "#e4eeff", "#d2ccf2", "#fde4f2", "#e5ffe1"];

  useEffect(() => {
    setForm(note || { ...formData });

    if (note) {
      const color =
        JSON.parse(note.configuration)?.BackgroundColor ?? "#feff9c";

      setColor(color);
      setForm((prevFormData) => ({
        ...prevFormData,
        configuration: {
          BackgroundColor: color,
        },
      }));
    }
  }, [note]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.content) {
      newErrors.value = "Content is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const payload = form;
      payload.configuration = JSON.stringify(form.configuration);

      const result = await axios.post("api/ToDos", payload);

      setForm({ id: 0, content: "", configuration: "" });
      console.log(result);
      onSubmit(result.data?.id);
    }
  };

  const handleColorChange = (color) => {
    setColor(color);
    setForm((prevFormData) => ({
      ...prevFormData,
      configuration: {
        BackgroundColor: color,
      },
    }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="measureUnit">Content *</label>
          <InputTextarea
            id="content"
            name="content"
            autoResize={true}
            value={form?.content || ""}
            onChange={handleChange}
            autoComplete="off"
            style={{ minHeight: "200px" }}
          />
          {errors.value && <label className="p-error">{errors.value}</label>}
        </div>
        <div className="p-card-footer note-footer">
          <div>
            {colors.map((color) => (
              <span
                key={color}
                onClick={() => handleColorChange(color)}
                style={{
                  display: "inline-block",
                  backgroundColor: color,
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  marginRight: "5px",
                  border: color === selectedColor ? "2px solid #333" : "none",
                }}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </div>
  );
};

export default UpsertTodo;
