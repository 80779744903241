import React, { useEffect, useState, useRef } from "react";

import axios from "axios";

import printJS from "print-js";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";

import { currencyAmount } from "../../../helpers/TemplateHelper";

function IncomeExpensesReport() {
  const printStyles = `
    body {font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; }
    label, div {font-size: 11px}
    strong {font-size: 10px}
    h4 {font-size: 13px}
    table, td, th {border: 0.5px groove ; font-size: 11px; padding: 4px} 
    table {width: 100%; border-collapse: collapse; margin-bottom: 10px}
    th {font-weight: 400; text-align: left, padding: 4px}
    .updated-by {width: 130px !important}
    .updated {width: 130px !important}
    .text-align-right {text-align: right}
    .text-align-left {text-align: left}
    .border-none td {border: none; font-size: 11px; padding: 1px}`;

  const toast = useRef(null);

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  const currentDate = new Date();

  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  const [totalExpense, setTotalExpense] = useState(0);

  const [totalPayment, setTotalPayment] = useState(0);

  const [totalUserPayment, setTotalUserPayment] = useState(0);
  const [reportData, setReportData] = useState(false);

  const [enabledExpenses, setEnabledExpenses] = useState([]);
  const [paymentData, setPaymentData] = useState([]);

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  const totalEnabledExpenses = enabledExpenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );

  const totalPayments = paymentData.reduce(
    (total, payment) => total + payment.amount,
    0
  );

  useEffect(() => {
    fetchData();

    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const firstName = decodedPayload.firstName;
      const lastName = decodedPayload.lastName;
      setUserFirstName(firstName);
      setUserLastName(lastName);
    }
  }, []);

  const fetchData = () => {
    if (fromDate && toDate) {
      const from = fromDate?.toISOString();
      const to = toDate?.toISOString();

      axios
        .get(`/api/expenses/enableByDate?fromDate=${from}&toDate=${to}`)
        .then((res) => {
          setEnabledExpenses(res.data);
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred while fetching enabled expenses.",
            life: 3000,
          });
        });

      axios
        .get(`/api/expenses/totalExpense?fromDate=${from}&toDate=${to}`)
        .then((res) => {
          setTotalExpense(res.data);
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred while fetching total expenses.",
            life: 3000,
          });
        });

      axios
        .get(`/api/payments/totalPayment?fromDate=${from}&toDate=${to}`)
        .then((res) => {
          setTotalPayment(res.data);
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred while fetching total payments.",
            life: 3000,
          });
        });

      axios
        .get(`/api/payments/paymentsByDate?fromDate=${from}&toDate=${to}`)
        .then((res) => {
          setPaymentData(res.data);
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred while fetching payments.",
            life: 3000,
          });
        });

      axios
        .get(`/api/userPayments/totalUserPayment?fromDate=${from}&toDate=${to}`)
        .then((res) => {
          setTotalUserPayment(res.data);
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred while fetching total payments.",
            life: 3000,
          });
        });

      setReportData(true);
    } else {
      setReportData(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please select date range",
        life: 3000,
      });
    }
  };

  const calculateProfit = () => {
    const profit = totalPayment - (totalUserPayment + totalExpense);
    return profit;
  };

  const profitAmount = calculateProfit();

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  function formatTableDate(dateString) {
    const options = {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const formattedDate = `${formatDate(currentDate)} - ${formatTime(
    currentDate
  )}`;

  const handlePrint = () => {
    printJS({
      printable: "income-report",
      type: "html",
      scanStyles: false,
      style: printStyles,
    });
  };

  return (
    <div className="container">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between">
        <div>
          <h4 className="text-primary mb-1">Income & Expenses Report</h4>
          <label className="text-secondary">
            View income & expenses report and print
          </label>
        </div>
        <div className="flex gap-2 align-items-center flex-wrap">
          <span className="filter-label">From Date : </span>
          <Calendar
            value={fromDate}
            onChange={(e) => setFromDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <span className="filter-label">To Date : </span>
          <Calendar
            value={toDate}
            onChange={(e) => setToDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <Button
            style={{ height: "33px" }}
            label="Refresh Data"
            severity="warning"
            onClick={() => fetchData()}
          />
          <Button
            style={{ height: "33px" }}
            label="Print Report"
            icon="pi pi-print"
            onClick={handlePrint}
          />
        </div>
      </div>
      {reportData && fromDate && toDate && (
        <div
          id="income-report"
          style={{ fontSize: "11px !important", marginTop: "8px" }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: "200px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="report-header">
                    <h4>Income & Expenses Report</h4>
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <div>Created: {formattedDate}</div>
                    <div>
                      Created By: {userFirstName} {""} {userLastName}{" "}
                    </div>
                    <div>
                      From {fromDate && formatDate(fromDate)} to{" "}
                      {toDate && formatDate(toDate)}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: "18px",
                    }}
                  >
                    <img
                      src={require("../../../images/logo.png")}
                      alt="logo"
                      style={{ height: "50px" }}
                    />
                  </div>
                  <div
                    className="text-align-right text-right"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>RAGAMA - WALPOLA - WELIMADA - NUWARAELIYA</label>
                    <label>Email : geethcurtains@gmail.com</label>
                    <label>Phone : 0718021976 - 0771786299</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="ProfitSummary"
            className="profit-summary flex flex-column flex-wrap summary"
            style={{
              marginTop: "10px",
            }}
          >
            <table className="order-table">
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    className="profit-summary-heading text-align-left text-left"
                  >
                    <strong>Income & Expenses Summary</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Payment Receives :</td>
                  <td className="text-align-right text-right">
                    {`+ ${currencyAmount(totalPayment)} LKR`}
                  </td>
                </tr>
                <tr>
                  <td>Total Expenses:</td>
                  <td className="text-align-right text-right">
                    {`-  ${currencyAmount(totalExpense)} LKR`}
                  </td>
                </tr>

                <tr>
                  <td>Total User Payments:</td>
                  <td className="text-align-right text-right">
                    {`- ${currencyAmount(totalUserPayment)} LKR`}
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Profit:</strong>
                  </td>
                  <td className="text-align-right text-right">
                    <strong>{`${currencyAmount(profitAmount)} LKR`}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-4">
            <strong style={{ marginBottom: "5px" }}>Expenses</strong>
            <table className="order-table" style={{ marginTop: "8px" }}>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Branch</th>
                  <th>Category</th>

                  <th>Remark</th>
                  <th className="updated" style={{ width: "140px" }}>
                    Updated
                  </th>
                  <th className="updated-by" style={{ width: "180px" }}>
                    Updated By
                  </th>
                  <th style={{ width: "120px" }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {enabledExpenses?.map((expense) => (
                  <tr key={expense.id}>
                    <td>{expense.id.toString().padStart(8, "0")}</td>
                    <td>{expense.branch}</td>
                    <td>{expense.category}</td>

                    <td>{expense.remark}</td>
                    <td>{formatTableDate(expense.updated)}</td>
                    <td>{expense.updatedByName}</td>
                    <td className="text-align-right text-right">{`${currencyAmount(
                      expense.amount
                    )} LKR`}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="6" style={{ textAlign: "right" }}>
                    <strong>Total Expenses :</strong>
                  </td>
                  <td className="text-align-right text-right">
                    <strong>{`${currencyAmount(
                      totalEnabledExpenses
                    )} LKR`}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-4">
            <strong style={{ marginBottom: "5px" }}>Income</strong>
            <table className="order-table" style={{ marginTop: "8px" }}>
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Bill No</th>
                  <th className="updated" style={{ width: "180px" }}>
                    Updated
                  </th>
                  <th style={{ width: "120px" }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {paymentData?.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.orderId.toString().padStart(8, "0")}</td>

                    <td>
                      {
                        paymentData.filter(
                          (p) => p.orderId === payment.orderId
                        )[0].referenceNo
                      }
                    </td>
                    <td>{formatTableDate(payment.updated)}</td>
                    <td className="text-align-right text-right">{`${currencyAmount(
                      payment.amount
                    )} LKR`}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="3" style={{ textAlign: "right" }}>
                    <strong>Total Payments :</strong>
                  </td>
                  <td className="text-align-right text-right">
                    <strong>{`${currencyAmount(totalPayments)} LKR`}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default IncomeExpensesReport;
