export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
  return date.toLocaleDateString("en-GB", options);
};

export const formatTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = { hour: "2-digit", minute: "2-digit" };
  return date.toLocaleTimeString("en-US", options);
};

export const formatDateToString = (date) => {
  return date ? date.toLocaleDateString().split("T")[0] : null;
};

export const ceil = (value, exp) => {
  return decimalAdjust("ceil", value, exp);
};

export const floor = (value, exp) => {
  return decimalAdjust("floor", value, exp);
};

export const currencyAmount = (x) => {
  if (x === null || x === undefined || isNaN(x)) {
    return "";
  }
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const currencyRate = (y) => {
  if (y == null || y === undefined || isNaN(y)) {
    return "";
  }
  return y.toFixed(4).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const decimalAdjust = (type, value, exp) => {
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }

  value = value.toString().split("e");
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));

  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
};

export const FormattedDate = ({ date }) => {
  return (
    <span>
      {new Date(date).toLocaleString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })}
    </span>
  );
};

export const DateOnlyFormat = ({ date }) => {
  return (
    <span>
      {new Date(date).toLocaleString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })}
    </span>
  );
};
