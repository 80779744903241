import { useEffect, useState } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

import { events } from "../../../const";

const formData = {
  id: 0,
  orderId: 0,
  eventName: "",
  remark: "",
};

const Event = ({
  visible,
  onCancel,
  orderId,
  toastrRef,
  onEventAdded,
  event,
}) => {
  const [form, setForm] = useState({});
  const [availableEvents, setAvailableEvents] = useState(events);

  useEffect(() => {
    setForm(event || { ...formData });
  }, [visible, event]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.orderId = orderId;

    axios
      .post("/api/Event", form)
      .then((response) => {
        toastrRef.current.show({
          severity: "success",
          summary: "Event Added / Updated",
          detail: "Event has been successfully added / updated.",
          life: 3000,
        });
        const updatedEvents = availableEvents.filter(
          (eventOption) => eventOption !== form.eventName
        );
        setAvailableEvents(updatedEvents);

        onEventAdded(response.data);
        onCancel();
      })
      .catch((error) => {
        console.error("Error adding / updating event:", error);

        toastrRef.current.show({
          severity: "error",
          summary: "Event Error",
          detail: "An error occurred while adding the event. Please try again.",
          life: 3000,
        });
      });
  };

  return (
    <Dialog
      visible={visible}
      onHide={onCancel}
      header="Manage TimeLine"
      style={{ width: "400px", Height: "fit-content" }}
    >
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="eventName">Select an event:</label>
          <Dropdown
            id="eventName"
            value={form.eventName}
            options={availableEvents.map((eventOption) => ({
              label: eventOption,
              value: eventOption,
            }))}
            onChange={(e) => setForm({ ...form, eventName: e.value })}
            placeholder="Select an event"
            required
          />
        </div>
        <div className="p-field">
          <label htmlFor="remark">Remark:</label>
          <InputTextarea
            id="remark"
            rows={5}
            value={form.remark}
            onChange={(e) => setForm({ ...form, remark: e.target.value })}
            autoResize
          />
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text"
            onClick={onCancel}
          />
          <Button label="Submit" type="submit" />
        </div>
      </form>
    </Dialog>
  );
};

export default Event;
