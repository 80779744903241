import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import { currencyAmount, DateOnlyFormat } from "../../helpers/TemplateHelper";

import UserPaymentDialog from "../components/UserPaymentDialog";

const UserPayments = () => {
  const toast = useRef(null);
  const [data, setData] = useState([]);
  const [userRole, setUserRole] = useState(null);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [height, setHeight] = useState(0);

  const dt = useRef(null);

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  useEffect(() => {
    fetchData();

    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const role = decodedPayload.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight;
      const specificAmount = 320;
      setHeight(vh - specificAmount);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/UserPayments");
      if (response.data.constructor === Array) setData(response.data);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while getting data. Please try again.",
        life: 3000,
      });
    }
  };

  const confirmStatus = async (rowData, isEnabled) => {
    try {
      const updatedData = { ...rowData, isEnabled };
      const response = await axios.post(`/api/UserPayments`, updatedData);

      if (response.status === 200 || response.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${isEnabled ? "activated" : "deactivated"} successfully`,
          life: 3000,
        });

        const updatedIndex = data.findIndex((item) => item.id === rowData.id);
        if (updatedIndex !== -1) {
          data[updatedIndex] = updatedData;
          setData([...data]);
        }
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "An error occurred while enabling / disabling user. Please try again.",
        life: 3000,
      });
    }
  };

  const statusTemplate = (rowData) => {
    const handleStatus = (isEnabled) => {
      confirmDialog({
        message: `Are you sure you want to ${
          isEnabled ? "activate" : "deactivate"
        } this user payment?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => confirmStatus(rowData, isEnabled),
      });
    };
    return (
      <>
        {userRole === "admin" && (
          <div className="p-field-checkbox">
            <Checkbox
              inputId={`statusCheckbox_${rowData.id}`}
              checked={rowData.isEnabled}
              onChange={(e) => handleStatus(e.checked)}
              tooltip={rowData.isEnabled ? "Deactivate" : "Activate"}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="card flex flex-column gap-4">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between align-items-center">
        <div>
          <h4 className="text-primary mb-1">User Payments</h4>
          <label className="text-secondary">
            View all User Payments & add them
          </label>
        </div>
        <div className="flex gap-2 align-items-center">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm"
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
            />
          </span>
          <Button
            label="Refresh"
            icon="pi pi-refresh"
            severity="help"
            onClick={fetchData}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            severity="success"
            onClick={() => dt.current.exportCSV({ selectionOnly: false })}
          />
          <Button
            label="Add Payment"
            icon="pi pi-plus"
            className="p-button-primary p-mb-3"
            onClick={() => setDialogVisible(true)}
          />
        </div>
      </div>
      <Dialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        header="Add Payment"
        style={{ width: "400px", minHeight: "fit-content" }}
      >
        <UserPaymentDialog
          onCancel={() => setDialogVisible(false)}
          onSubmit={() => {
            setDialogVisible(false);
            fetchData();
          }}
          toast={toast}
        />
      </Dialog>
      <DataTable
        ref={dt}
        value={data}
        className="p-datatable-striped"
        paginator
        rows={25}
        rowsPerPageOptions={[25, 50, 100]}
        globalFilter={globalFilter}
        scrollable
        scrollHeight={height}
      >
        <Column header="Status" body={statusTemplate}></Column>
        <Column
          sortable
          field="id"
          header="Payment ID"
          body={(row) => row.id.toString().padStart(8, "0")}
        />
        <Column sortable field="userName" header="Employee Name" />
        <Column
          sortable
          field="amount"
          header="Amount"
          body={(rowData) => `${currencyAmount(rowData.amount)}`}
          style={{ textAlign: "right" }}
        />
        <Column sortable field="remark" header="Remark" />
        <Column
          sortable
          field="updated"
          header="Updated"
          body={(row) => <DateOnlyFormat date={row.updated} />}
        />
        <Column sortable field="updatedByName" header="Updated By" />
      </DataTable>
    </div>
  );
};

export default UserPayments;
