import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import KeyValuePairDialog from "../components/KeyValuePairDialog";

const KeyValuePairs = () => {
  const toast = useRef(null);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [height, setHeight] = useState(0);

  const dt = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight;
      const specificAmount = 320;
      setHeight(vh - specificAmount);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/keyValuePairs");
      if (response.data.constructor === Array) setData(response.data);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while getting data. Please try again.",
        life: 3000,
      });
    }
  };

  const editCategory = (rowData) => {
    setSelected(rowData);
    setDialogVisible(true);
  };

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const handleDelete = (value) => {
    confirmDialog({
      message: "Are you sure you want to delete this key value pair?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDelete(value),
    });
  };

  const confirmDelete = async (value) => {
    axios
      .delete(`/api/keyValuePairs/${value.id}`)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Key Value Pair Deleted",
          detail: "Key value pair has been successfully deleted.",
          life: 3000,
        });
        fetchData();
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Key Value Pair Error",
          detail:
            "An error occurred while deleting the key value pair. Please try again.",
          life: 3000,
        });
      });
  };

  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2"
          icon="pi pi-pencil"
          tooltip="Edit"
          tooltipOptions={{ event: "both" }}
          onClick={() => {
            editCategory(rowData);
          }}
        />
        <Button
          className="p-button-rounded p-button-danger"
          icon="pi pi-trash"
          onClick={() => handleDelete(rowData)}
        />
      </>
    );
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  return (
    <div className="card flex flex-column gap-4">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between align-items-center">
        <div>
          <h4 className="text-primary mb-1">Key Value Pairs</h4>
          <label className="text-secondary">
            Add, edit, view key value pairs
          </label>
        </div>
        <div className="flex gap-2 align-items-center">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm"
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
            />
          </span>
          <Button
            label="Refresh"
            icon="pi pi-refresh"
            severity="help"
            onClick={fetchData}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            severity="success"
            onClick={() => exportCSV(false)}
          />
          <Button
            label="Add Key Value Pair"
            icon="pi pi-plus"
            className="p-button-primary p-mb-3"
            onClick={() => editCategory(null)}
          />
        </div>
      </div>
      <Dialog
        visible={dialogVisible}
        onHide={hideDialog}
        header="Add / Edit Key Value Pair"
        style={{ width: "400px", minHeight: "fit-content" }}
      >
        <KeyValuePairDialog
          keyvalue={selected}
          onCancel={hideDialog}
          onSubmit={() => {
            hideDialog();
            fetchData();
          }}
        />
      </Dialog>
      <DataTable
        ref={dt}
        value={data}
        className="p-datatable-striped"
        paginator
        rows={25}
        rowsPerPageOptions={[25, 50, 100]}
        globalFilter={globalFilter}
        scrollable
        scrollHeight={height}
      >
        <Column
          header=""
          body={actionTemplate}
          style={{ width: "100px" }}
        ></Column>

        <Column sortable field="key" header="Key" />
        <Column sortable field="value" header="Value" />
      </DataTable>
    </div>
  );
};

export default KeyValuePairs;
