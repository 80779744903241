import React, { useEffect, useState } from "react";

import axios from "axios";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

const formData = {
  id: 0,
  orderId: 0,
  referenceNo: "",
  amount: null,
  remark: "",
};

const PaymentDialog = ({
  visible,
  onCancel,
  orderId,
  toastrRef,
  onPaymentAdded,
  payment,
}) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm(payment || { ...formData });
  }, [visible, payment]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.orderId = orderId;

    axios
      .post("/api/Payments", form)
      .then((response) => {
        toastrRef.current.show({
          severity: "success",
          summary: "Payment Added / Updated",
          detail: "Payment has been successfully added / updated.",
          life: 3000,
        });
        onPaymentAdded(response.data);
        onCancel();
      })
      .catch((error) => {
        console.error("Error adding / updating payment:", error);
        toastrRef.current.show({
          severity: "error",
          summary: "Payment Error",
          detail:
            "An error occurred while adding / updating the payment. Please try again.",
          life: 3000,
        });
      });
  };

  return (
    <Dialog
      header="Add / Edit Payment"
      visible={visible}
      onHide={onCancel}
      modal
      style={{ width: "400px", minHeight: "fit-content" }}
    >
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="referenceNo">Bill No*</label>
          <InputText
            id="referenceNo"
            name="referenceNo"
            value={form.referenceNo}
            onChange={(e) => setForm({ ...form, referenceNo: e.target.value })}
            autoComplete="off"
            required
          />
        </div>
        <div className="p-field">
          <label htmlFor="amount">Amount*</label>
          <InputNumber
            inputId="amount"
            name="amount"
            value={form.amount}
            onValueChange={(e) => setForm({ ...form, amount: e.value })}
            minFractionDigits={2}
            prefix="LKR "
            locale="en-LK"
            autoComplete="off"
            required
          />
        </div>
        <div className="p-field">
          <label htmlFor="remark">Remark</label>
          <InputTextarea
            id="remark"
            name="remark"
            rows={4}
            value={form.remark}
            onChange={(e) => setForm({ ...form, remark: e.target.value })}
          />
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </Dialog>
  );
};

export default PaymentDialog;
