import React, { useState, useRef } from "react";

import axios from "axios";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import Loader from "./loader-service/Loader";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const toast = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post("/api/login", formData)
      .then((res) => {
        const token = res.data.jwt;
        localStorage.setItem("token", token);

        const expirationTime = new Date(res.data.expirationTime).getTime();

        setLoading(false);

        const currentTime = Date.now();

        if (expirationTime < currentTime) {
          toast.current.show({
            severity: "error",
            summary: "Session Expired",
            detail: "Your session has expired. Please log in again.",
            life: 3000,
          });
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          window.location.href = "pages/dashboard";
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "Invalid username, password or unauthorized role. Please try again.",
          life: 3000,
        });
      });
  };

  return (
    <div style={{ background: "#fff" }}>
      <Toast ref={toast} position="bottom-right" />
      <Loader showLoader={loading} />

      <div
        className="flex align-content-center flex-wrap"
        style={{ height: "calc(100vh - 40px)" }}
      >
        <div className="grid" style={{ width: "100%" }}>
          <div className="col" style={{ textAlign: "center" }}>
            <img
              src={require("./images/login.png")}
              alt="loging iamge"
              className="img-fluid"
            />
          </div>
          <div className="col flex justify-content-center align-content-center flex-wrap">
            <div style={{ width: "300px" }}>
              <div className="flex justify-content-center">
                <img
                  src={require("./images/logo.png")}
                  alt="logo"
                  style={{ width: "200px" }}
                />
              </div>
              <h3 className="mt-4" style={{ paddingLeft: "0px" }}>
                Back Office
              </h3>
              <label style={{ paddingLeft: "0px" }}>
                Please Login to continue
              </label>
              <form className="mt-4" onSubmit={handleLogin}>
                <div style={{ display: "block", clear: "both" }}>
                  <label
                    style={{
                      display: "block",
                      clear: "both",
                    }}
                  >
                    Username *
                  </label>
                  <InputText
                    id="username"
                    name="username"
                    placeholder="Username"
                    autoComplete="off"
                    type="text"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div
                  className="mt-4"
                  style={{ display: "block", clear: "both" }}
                >
                  <label
                    style={{
                      display: "block",
                      clear: "both",
                    }}
                  >
                    Password *
                  </label>
                  <InputText
                    id="passsword"
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="off"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div
                  className="text-lg-start mt-4 pt-2"
                  style={{ paddingLeft: "5px" }}
                >
                  <Button type="submit" label="Login" disabled={loading} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-center text-md-start justify-content-between px-4 px-xl-5 bg-primary text-white"
        style={{
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          Geeth Curtains - Back Office v1.0.0.0 © 2023 - &nbsp; All rights
          reserved.
        </div>
        <div>
          <a
            className="caption"
            href="http://www.elixirlk.com"
            style={{ color: "white" }}
          >
            <strong>Elixir</strong> - Realizing Dreams through Enchanting
            Innovations !
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
