import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import axios from "axios";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";

import EditUser from "./EditUser";

const Users = () => {
  const toast = useRef(null);

  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(25);
  const [globalFilter, setGlobalFilter] = useState(null);

  const [height, setHeight] = useState(0);

  const dt = useRef(null);

  useEffect(() => {
    fetchData();
  }, [showUpdateDialog, showAddDialog]);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/User");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight;
      const specificAmount = 320;
      setHeight(vh - specificAmount);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const updateItem = async (rowData) => {
    try {
      const response = await axios.get(`/api/User/${rowData.id}`);
      setSelectedItem(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }

    setShowUpdateDialog(true);
  };

  const closeUpdateDialog = () => {
    setShowUpdateDialog(false);
  };

  const onPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const addNewItem = async (newItem) => {
    try {
      const response = await axios.post("/api/User", newItem);
      const newItemWithId = { ...response.data, id: response.data.id };
      setData([...data, newItemWithId]);
      setShowAddDialog(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Duplicate User Name",
        life: 3000,
      });
      console.error("Error creating user:", error);
    }
  };

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  const handleDelete = (userId) => {
    confirmDialog({
      message: "Are you sure you want to remove this user?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDelete(userId),
    });
  };

  const confirmDelete = async (userId) => {
    const token = localStorage.getItem("token");
    const decodedPayload = decodeJwt(token);

    const loggedInUserId = decodedPayload.userId;

    if (userId == loggedInUserId) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Cannot remove your own account",
        life: 3000,
      });
    } else {
      try {
        await axios.delete(`/api/User/${userId}`);
        const updatedData = data.filter((item) => item.id !== userId);
        toast.current.show({
          severity: "success",
          summary: "User Removed",
          detail: "User has been successfully removed.",
          life: 3000,
        });
        setData(updatedData);
      } catch (error) {
        console.error("Error deleting user:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while removing user. Please try again.",
          life: 3000,
        });
      }
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Link to={`/pages/user/${rowData.id}`}>
          <Button
            icon="pi pi-external-link"
            className="p-button-rounded p-button-info mr-2"
            tooltip="View"
            tooltipOptions={{ event: "both" }}
          ></Button>
        </Link>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-primary mr-2"
          tooltip="Edit"
          tooltipOptions={{ event: "both" }}
          onClick={() => updateItem(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          tooltip="Remove"
          tooltipOptions={{ event: "both" }}
          onClick={() => handleDelete(rowData.id)}
        />
      </React.Fragment>
    );
  };

  const handleAddUser = () => {
    setShowAddDialog(true);
  };

  const closeAddDialog = () => {
    setShowAddDialog(false);
  };

  const handleUpdateItem = async (updatedItem) => {
    try {
      await axios.put(`/api/User/${updatedItem.id}`, updatedItem);
      const updatedData = data.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item
      );
      setData(updatedData);
      setShowUpdateDialog(false);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const columns = [
    { body: actionTemplate, header: "", style: { width: "100px" } },
    { field: "firstName", header: "First Name", sortable: true },
    { field: "lastName", header: "Last Name", sortable: true },
    {
      field: "role",
      header: "Role",
      sortable: true,
      body: (rowData) => (
        <Tag
          severity="info"
          className="mr-2"
          icon="pi pi-user"
          value={rowData?.role?.toUpperCase()}
        />
      ),
    },
    { field: "branch", header: "Branch", sortable: true },
    { field: "username", header: "Username", sortable: true },
    { field: "email", header: "Email", sortable: true },
    { field: "phoneNumber", header: "Phone", sortable: true },
    {
      field: "status",
      header: "Status",
      body: (data) =>
        data.status === 1 ? (
          <Tag severity="success" icon="pi pi-check" value="ACTIVE" />
        ) : (
          <Tag severity="danger" icon="pi pi-lock" value="INACTIVE" />
        ),
    },
  ];

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  return (
    <div className="card">
      <Toast ref={toast} position="bottom-right" />
      <div className="addusercontainer">
        <div>
          <h4 className="text-primary mb-1">Users</h4>
          <label className="text-secondary">Add, edit, view users</label>
        </div>
        <div className="flex gap-2 align-items-center">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm"
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
            />
          </span>
          <Button
            label="Refresh"
            icon="pi pi-refresh"
            severity="help"
            onClick={fetchData}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            severity="success"
            onClick={() => exportCSV(false)}
          />
          <Button
            label="Add User"
            icon="pi pi-user-plus"
            className="p-button-primary p-mb-3"
            onClick={handleAddUser}
          />
        </div>
      </div>
      <Dialog
        visible={showAddDialog}
        onHide={closeAddDialog}
        header="Add User"
        style={{ width: "400px", minHeight: "fit-content" }}
      >
        <EditUser
          isAddDialogOpen={showAddDialog}
          addNewItem={addNewItem}
          onCancel={closeAddDialog}
        />
      </Dialog>

      <Dialog
        visible={showUpdateDialog}
        onHide={closeUpdateDialog}
        header="Edit User"
        style={{ width: "400px", minHeight: "fit-content" }}
      >
        {selectedItem && (
          <EditUser
            isEditDialogOpen={showUpdateDialog}
            setShowUpdate={setShowUpdateDialog}
            addNewItem={handleUpdateItem}
            initialValues={selectedItem}
            onCancel={closeUpdateDialog}
          />
        )}
      </Dialog>
      <DataTable
        ref={dt}
        value={data}
        className="p-datatable-striped"
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}
        paginator
        first={first}
        rows={rows}
        rowsPerPageOptions={[25, 50, 100]}
        onPage={onPage}
        globalFilter={globalFilter}
        scrollable
        scrollHeight={height}
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            body={column.body}
            sortable={column.sortable}
          />
        ))}
      </DataTable>
    </div>
  );
};
export default Users;
