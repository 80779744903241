import React, { useEffect, useState } from "react";

import AppMenuitem from "./AppMenuitem";
import { MenuProvider } from "./context/menucontext";

const AppMenu = () => {
  const [userRole, setUserRole] = useState(null);

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const role = decodedPayload.role;
      setUserRole(role);
    }
  }, []);

  const model = [
    {
      label: "Home",
      items: [{ label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" }],
    },
    {
      label: "Pages",
      items: [
        {
          label: "Quotations",
          icon: "pi pi-fw pi-clone",
          to: "/pages/quotations",
        },
        {
          label: "Orders",
          icon: "pi pi-fw pi-list",
          to: "/pages/orders",
        },
        {
          label: "Rejected Orders",
          icon: "pi pi-times-circle",
          to: "/pages/rejectOrders",
        },
        {
          label: "Completed Orders",
          icon: "pi pi-check-circle",
          to: "/pages/completed",
        },
        {
          label: "To Do",
          icon: "pi pi-file-edit",
          to: "/pages/to-do",
        },
      ],
    },
    userRole === "admin"
      ? {
          label: "Admin Pages",
          items: [
            {
              label: "Users",
              icon: "pi pi-user",
              to: "/pages/users",
            },
            {
              label: "User Payments",
              icon: "pi pi-money-bill",
              to: "/pages/userPayments",
            },
            {
              label: "Expenses",
              icon: "pi pi-dollar",
              to: "/pages/expenses",
            },
            {
              label: "Debtors List",
              icon: "pi pi-dollar",
              to: "/pages/debtors-list",
            },
          ],
        }
      : null,
    userRole === "admin"
      ? {
          label: "System Configurations",
          items: [
            {
              label: "Contribution Types",
              icon: "pi pi-id-card",
              to: "/pages/contributionTypes",
            },
            {
              label: "Key Value Pairs",
              icon: "pi pi-wrench",
              to: "/pages/keyValues",
            },
          ],
        }
      : null,
    userRole === "admin"
      ? {
          label: "Stock Management",
          items: [
            {
              label: "Items",
              icon: "pi pi-id-card",
              to: "/pages/items",
            },
            {
              label: "Stocks",
              icon: "pi pi-wrench",
              to: "/pages/stocks",
            },
            {
              label: "Good Receive",
              icon: "pi pi-wrench",
              to: "/pages/good-receive",
            },
            {
              label: "Shortages & Excesses",
              icon: "pi pi-wrench",
              to: "/pages/shortage-excess",
            },
            {
              label: "Item Usage",
              icon: "pi pi-wrench",
              to: "/pages/item-usage",
            },
          ],
        }
      : null,
    {
      label: "Reports",
      items: [
        {
          label: "Pole Requirement Report",
          icon: "pi pi-file",
          to: "/pages/reports/pole-requirement-report",
        },
        userRole === "admin"
          ? {
              label: "Contribution Report",
              icon: "pi pi-file",
              to: "/pages/reports/contribution-report",
            }
          : {},
        userRole === "admin"
          ? {
              label: "Fixing & Transportation",
              icon: "pi pi-file",
              to: "/pages/reports/fixing-transportation-report",
            }
          : {},
        userRole === "admin"
          ? {
              label: "Income & Expenses Report",
              icon: "pi pi-file",
              to: "/pages/reports/income-report",
            }
          : {},
      ],
    },
  ];

  const filteredModel = model.filter((item) => item !== null);

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {filteredModel.map((item, i) => {
          return !item.seperator ? (
            <AppMenuitem item={item} root={true} index={i} key={item.label} />
          ) : (
            <li className="menu-separator"></li>
          );
        })}
      </ul>
    </MenuProvider>
  );
};

export default AppMenu;
