import React, { useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

import axios from "axios";

import { currencyAmount } from "../../helpers/TemplateHelper";

const currencies = [
  "AFN",
  "ARS",
  "AWG",
  "AUD",
  "AZN",
  "BSD",
  "BBD",
  "BDT",
  "BYR",
  "BZD",
  "BMD",
  "BOB",
  "BAM",
  "BWP",
  "BGN",
  "BRL",
  "BND",
  "KHR",
  "CAD",
  "KYD",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "HRK",
  "CUP",
  "CZK",
  "DKK",
  "DOP",
  "XCD",
  "EGP",
  "SVC",
  "EEK",
  "EUR",
  "FKP",
  "FJD",
  "GHC",
  "GIP",
  "GTQ",
  "GGP",
  "GYD",
  "HNL",
  "HKD",
  "HUF",
  "ISK",
  "INR",
  "IDR",
  "IRR",
  "IMP",
  "ILS",
  "JMD",
  "JPY",
  "JEP",
  "KZT",
  "KPW",
  "KRW",
  "KGS",
  "LAK",
  "LVL",
  "LBP",
  "LRD",
  "LTL",
  "MKD",
  "MYR",
  "MUR",
  "MXN",
  "MNT",
  "MZN",
  "NAD",
  "NPR",
  "ANG",
  "NZD",
  "NIO",
  "NGN",
  "NOK",
  "OMR",
  "PKR",
  "PAB",
  "PYG",
  "PEN",
  "PHP",
  "PLN",
  "QAR",
  "RON",
  "RUB",
  "SHP",
  "SAR",
  "RSD",
  "SCR",
  "SGD",
  "SBD",
  "SOS",
  "ZAR",
  "LKR",
  "SEK",
  "CHF",
  "SRD",
  "SYP",
  "TWD",
  "THB",
  "TTD",
  "TRY",
  "TRL",
  "TVD",
  "UAH",
  "GBP",
  "USD",
  "UYU",
  "UZS",
  "VEF",
  "VND",
  "YER",
  "ZWD",
];

const formData = {
  id: 0,
  orderId: 0,
  userId: 0,
  currency: null,
  currencyRate: null,
  deliveryCharge: null,
  amount: null,
};

const CurrencyConversionDialog = ({
  visible,
  onCancel,
  orderId,
  toastrRef,
  currencyConversion,
  onCurrencyConversionAdded,
}) => {
  const [form, setForm] = useState(currencyConversion ?? { ...formData });
  const [errors, setErrors] = useState({});

  function validate() {
    const newErrors = {};
    if (!form.currency) {
      newErrors.currency = "Currency Type is required";
    }
    if (!form.currencyRate) {
      newErrors.currencyRate = "Currency Rate is Requred";
    }
    if (!form.amount) {
      newErrors.amount = "Amount is Requred";
    }
    if (!form.deliveryCharge) {
      newErrors.deliveryCharge = "Delivery charge is Requred";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    form.orderId = orderId;

    axios
      .post("/api/CurrencyConversion", form)
      .then((response) => {
        toastrRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Currency Conversion updated successfully.",
          life: 3000,
        });
        onCurrencyConversionAdded(response.data);
        onCancel();
      })
      .catch(() => {
        toastrRef.current.show({
          severity: "error",
          summary: "Currency Conversion Error",
          detail:
            "An error occurred while adding / updating the Currency Conversion. Please try again.",
          life: 3000,
        });
      });

    onCurrencyConversionAdded(form);
  };

  const getTotalAmount = () => {
    return +form?.currencyRate * (+form?.amount + +form?.deliveryCharge) ?? 0;
  };

  return (
    <Dialog
      header="Add Currency Conversion"
      visible={visible}
      onHide={onCancel}
      style={{ width: "400px", minHeight: "fit-content" }}
    >
      <form onSubmit={handleSubmit} className="p-fluid">
        <div className="p-field">
          <label htmlFor="currency">Currency:</label>
          <Dropdown
            value={form?.currency}
            options={currencies}
            onChange={(e) => setForm({ ...form, currency: e.target.value })}
            placeholder="Select Currency"
          />
          {errors.currency && (
            <label className="p-error">{errors.currency}</label>
          )}
        </div>

        <div className="p-field">
          <label htmlFor="currencyRate">Currency Rate:</label>
          <InputNumber
            value={form?.currencyRate}
            onValueChange={(e) => setForm({ ...form, currencyRate: e.value })}
            mode="currency"
            currency="LKR"
          />
          {errors.currencyRate && (
            <label className="p-error">{errors.currencyRate}</label>
          )}
        </div>

        <div className="p-field">
          <label htmlFor="amount">Amount:</label>
          <InputNumber
            value={form?.amount}
            onValueChange={(e) => setForm({ ...form, amount: e.value })}
            mode="currency"
            currency={form?.currency ?? "USD"}
          />
          {errors.amount && <label className="p-error">{errors.amount}</label>}
        </div>

        <div className="p-field">
          <label htmlFor="deliveryCharge">Delivery Charge:</label>
          <InputNumber
            value={form?.deliveryCharge}
            onValueChange={(e) => setForm({ ...form, deliveryCharge: e.value })}
            mode="currency"
            currency={form?.currency ?? "USD"}
          />
          {errors.deliveryCharge && (
            <label className="p-error">{errors.deliveryCharge}</label>
          )}
        </div>

        <div className="p-field">
          <strong>LKR Total: {currencyAmount(getTotalAmount())}</strong>
        </div>

        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" onClick={handleSubmit} />
        </div>
      </form>
    </Dialog>
  );
};

export default CurrencyConversionDialog;
