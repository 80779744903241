import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

const formData = {
  id: 0,
  key: "",
  value: "",
};

const keys = ["Branch", "Expense", "ItemCategories", "Account", "Supplier"];

const KeyValuePairDialog = ({ keyvalue, onCancel, onSubmit }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const toast = useRef(null);

  useEffect(() => {
    setForm(keyvalue || { ...formData });
  }, [keyvalue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.key) {
      newErrors.key = "Key is required";
    }
    if (!form.value) {
      newErrors.value = "Value is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const result = await axios.post("/api/keyValuePairs", form);

        if (result.data?.id) {
          toast.current.show({
            severity: "success",
            summary: "Value Added/Updated",
            detail: "Value has been successfully added/updated.",
            life: 3000,
          });
          setForm({ key: "", value: "" });
          onSubmit();
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Value Error",
          detail: "An error occurred while adding value. Please try again.",
          life: 3000,
        });
      }
    }
  };

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="keyId">Key *</label>
          <Dropdown
            id="keyId"
            name="key"
            options={keys}
            onChange={handleChange}
            placeholder="Select Key"
          />
          {errors.key && <label className="p-error">{errors.key}</label>}
        </div>

        <div className="p-field">
          <label htmlFor="measureUnit">Value *</label>
          <InputText
            id="value"
            name="value"
            value={form?.value || ""}
            onChange={handleChange}
            autoComplete="off"
          />
          {errors.value && <label className="p-error">{errors.value}</label>}
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </div>
  );
};

export default KeyValuePairDialog;
