import React, { useState } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";

import { currencyAmount, FormattedDate } from "../../../helpers/TemplateHelper";

import PaymentDialog from "./PaymentDialog";

const PaymentsTable = ({ payments, onChange, toastrRef, orderStatus }) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleDelete = (payment) => {
    confirmDialog({
      message: "Are you sure you want to delete this payment?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDelete(payment),
    });
  };

  const confirmDelete = async (payment) => {
    axios
      .delete(`/api/Payments/${payment.id}`)
      .then((respose) => {
        toastrRef.current.show({
          severity: "success",
          summary: "Payment Deleted",
          detail: "Payment has been successfully deleted.",
          life: 3000,
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error deleting payment", error);
        toastrRef.current.show({
          severity: "error",
          summary: "Payment Error",
          detail:
            "An error occurred while deleting the payment. Please try again.",
          life: 3000,
        });
      });
  };

  const actionTemplate = (rowData) => {
    if (orderStatus === 1) {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => {
              setSelected(rowData);
              setDialogVisible(true);
            }}
          />
          <Button
            className="p-button-rounded p-button-danger"
            icon="pi pi-trash"
            onClick={() => handleDelete(rowData)}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <div className="pt-8">
        <h5 className="text-color-secondary">Payments</h5>
        <DataTable
          tableStyle={{ minWidth: "960px" }}
          value={payments}
          className="p-datatable-striped"
        >
          <Column
            style={{ width: "100px" }}
            header=""
            body={actionTemplate}
          ></Column>
          <Column sortable field="referenceNo" header="Bill No" />
          <Column
            style={{ width: "310px" }}
            sortable
            field="remark"
            header="Remark"
          />
          <Column
            style={{ width: "200px" }}
            sortable
            field="amount"
            header="Amount"
            headerStyle={{ textAlign: "right" }}
            bodyStyle={{ textAlign: "right" }}
            body={(rowData) => `${currencyAmount(rowData.amount)} LKR`}
          />
          <Column
            style={{ width: "150px" }}
            sortable
            field="created"
            header="Updated"
            body={(row) => <FormattedDate date={row.updated} />}
          />
          <Column
            style={{ width: "200px" }}
            sortable
            field="updatedByName"
            header="Updated By"
          />
        </DataTable>
      </div>
      <PaymentDialog
        visible={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        payment={selected}
        onPaymentAdded={() => onChange()}
        toastrRef={toastrRef}
      />
    </div>
  );
};

export default PaymentsTable;
