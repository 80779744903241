import React, { useEffect, useState, useRef } from "react";

import axios from "axios";

import printJS from "print-js";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import { currencyAmount } from "../../../helpers/TemplateHelper";

function ContributionReport() {
  const printStyles = `
    body {font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; }
    label, div {font-size: 11px}
    strong {font-size: 10px}
    h4 {font-size: 13px}
    table, td, th {border: 0.5px groove ; font-size: 11px; padding: 4px} 
    table {width: 100%; border-collapse: collapse; margin-bottom: 10px}
    th {font-weight: 400; text-align: left, padding: 4px}
    .updated-by {width: 130px !important}
    .updated {width: 100px !important}
    .text-align-right {text-align: right}
    .text-align-left {text-align: left}
    .border-none td {border: none; font-size: 11px; padding: 1px}`;

  const toast = useRef(null);
  const [order, setOrder] = useState(null);

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  const [selectedUser, setSelectedUser] = useState(-1);

  const currentDate = new Date();

  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  const [userDropdownOptions, setUserDropdownOptions] = useState([]);

  const [selectedUserLabel, setSelectedUserLabel] = useState("All");

  const [userPayments, setUserPayments] = useState([]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [remainingPayment, setRemainingPayment] = useState(0);

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const firstName = decodedPayload.firstName;
      const lastName = decodedPayload.lastName;
      setUserFirstName(firstName);
      setUserLastName(lastName);
    }
  }, []);

  useEffect(() => {
    setSelectedUser(-1);
    fetchData();

    axios
      .get("/api/User/active")
      .then((response) => {
        const users = response.data.map((user) => ({
          label: `${user.id === -1 ? "All" : `${user.id} -`} ${
            user.firstName
          } ${user.lastName}`,
          value: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        }));

        users.unshift({ label: "All", value: -1 });

        setUserDropdownOptions(users);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        toast.current.show({
          severity: "error",
          summary: "User Data Error",
          detail:
            "An error occurred while fetching user data. Please try again.",
          life: 3000,
        });
      });
  }, []);

  const fetchData = () => {
    if (selectedUser && fromDate && toDate) {
      const userId = selectedUser;
      const from = fromDate?.toISOString();
      const to = toDate?.toISOString();
      if (selectedUser === -1) {
        axios
          .get(`/api/reports/contributions/${userId}/${from}/${to}`)
          .then((res) => {
            setOrder(res.data);

            const totalContribution = res.data.reduce((acc, contribution) => {
              return acc + contribution.cost;
            }, 0);

            setTotalAmount(totalContribution);

            fetchUserPayments(-1, from, to, totalContribution);
          })
          .catch((error) => {
            console.log(error);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "An error occurred while processing your request.",
              life: 3000,
            });
          });
      } else {
        axios
          .get(`/api/reports/contributions/${userId}/${from}/${to}`)
          .then((res) => {
            setOrder(res.data);

            const totalContribution = res.data.reduce((acc, contribution) => {
              return acc + contribution.cost;
            }, 0);

            setTotalAmount(totalContribution);

            fetchUserPayments(userId, from, to, totalContribution);
          })
          .catch((error) => {
            console.log(error);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "An error occurred while processing your request.",
              life: 3000,
            });
          });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please select a employee and date range",
        life: 3000,
      });
    }
  };

  const fetchUserPayments = (userId, from, to, totalContribution) => {
    axios
      .get(`/api/reports/payments/${userId}/${from}/${to}`)
      .then((response) => {
        setUserPayments(response.data);

        const totalPayment = response.data.reduce((acc, userPayment) => {
          return acc + userPayment.amount;
        }, 0);

        setTotalPayments(totalPayment);

        const remaining = totalContribution - totalPayment;
        setRemainingPayment(remaining);
      })
      .catch((error) => {
        console.error("Error fetching UserPayments:", error);
        toast.current.show({
          severity: "error",
          summary: "User Payments Error",
          detail:
            "An error occurred while fetching user payments. Please try again.",
          life: 3000,
        });
      });
  };

  const handleUserDropdownChange = (e) => {
    setSelectedUser(e.value);
    const selectedUserOption = userDropdownOptions.find(
      (option) => option.value === e.value
    );
    if (selectedUserOption) {
      setSelectedUserLabel(selectedUserOption.label);
    } else {
      setSelectedUserLabel("");
    }
  };

  function formatTableDate(dateString) {
    const options = {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const formattedDate = `${formatDate(currentDate)} - ${formatTime(
    currentDate
  )}`;

  const handlePrint = () => {
    printJS({
      printable: "contributionwise-report",
      type: "html",
      scanStyles: false,
      style: printStyles,
    });
  };

  return (
    <div className="container">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between">
        <div>
          <h4 className="text-primary mb-1">Contribution Report</h4>
          <label className="text-secondary">
            View contribution report and print
          </label>
        </div>
        <div className="flex gap-2 align-items-center flex-wrap">
          <span className="user-label">Employee : </span>
          <Dropdown
            style={{ minWidth: "14rem" }}
            value={selectedUser}
            options={userDropdownOptions}
            onChange={handleUserDropdownChange}
            placeholder="Select Employee"
          />

          <span className="filter-label">From Date : </span>
          <Calendar
            value={fromDate}
            onChange={(e) => setFromDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <span className="filter-label">To Date : </span>
          <Calendar
            value={toDate}
            onChange={(e) => setToDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <Button
            style={{ height: "33px" }}
            label="Refresh Data"
            severity="warning"
            onClick={() => fetchData()}
          />
          <Button
            style={{ height: "33px" }}
            label="Print Report"
            icon="pi pi-print"
            onClick={handlePrint}
          />
        </div>
      </div>

      {selectedUser && fromDate && toDate && order?.length > 0 && (
        <div
          id="contributionwise-report"
          style={{ fontSize: "11px !important", marginTop: "8px" }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: "200px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "20px",
                  }}
                >
                  <div className="report-header">
                    <h4>Contribution Report</h4>
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <div>Created: {formattedDate}</div>
                    <div>
                      Created By: {userFirstName} {""} {userLastName}{" "}
                    </div>
                    <div className="pt-2" style={{ paddingTop: "8px" }}>
                      For {selectedUserLabel}
                    </div>
                    <div>
                      From {fromDate && formatDate(fromDate)} to{" "}
                      {toDate && formatDate(toDate)}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: "40px",
                    }}
                  >
                    <img
                      src={require("../../../images/logo.png")}
                      alt="logo"
                      style={{ height: "50px" }}
                    />
                  </div>
                  <div
                    className="text-align-right text-right"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>RAGAMA - WALPOLA - WELIMADA - NUWARAELIYA</label>
                    <label>Email : geethcurtains@gmail.com</label>
                    <label>Phone : 0718021976 - 0771786299</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="PaymentSummary"
            className="payment-summary mb-2 flex flex-column flex-wrap summary"
            style={{
              marginTop: "10px",
            }}
          >
            <table className="order-table">
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    className="payment-summary-heading text-align-left text-left"
                  >
                    <strong>Payment Summary</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Contribution Cost:</td>

                  <td className="text-align-right text-right">{`${currencyAmount(
                    totalAmount
                  )} LKR`}</td>
                </tr>
                <tr>
                  <td>Total Payments:</td>
                  <td className="text-align-right text-right">{`${currencyAmount(
                    totalPayments
                  )} LKR`}</td>
                </tr>
                <tr>
                  <td>Remaining Payment:</td>
                  <td className="text-align-right text-right">
                    <strong>{`${currencyAmount(remainingPayment)} LKR`}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {order?.length > 0 && (
            <div className="mt-4">
              <strong style={{ marginTop: "1rem" }}>
                Contribution Details
              </strong>
              <table className="order-table" style={{ marginTop: "8px" }}>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Type</th>
                    <th>Order Id</th>
                    <th>Customer Name</th>
                    <th>Value</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.map((contribution) => (
                    <tr key={contribution.id}>
                      <td>{contribution.userName}</td>
                      <td>
                        {contribution.type} / {contribution.measureUnit}
                      </td>
                      <td>
                        {contribution.orderId.toString().padStart(8, "0")}
                      </td>
                      <td>
                        {contribution.order.customerDetails?.firstName}{" "}
                        {contribution.order.customerDetails?.lastName}
                      </td>

                      <td className="text-align-right text-right">
                        {contribution.value}
                      </td>
                      <td className="text-align-right text-right">{`${currencyAmount(
                        contribution.cost
                      )} LKR`}</td>
                    </tr>
                  ))}
                  {order && order.length > 0 && (
                    <tr>
                      <td colSpan="5">
                        <strong>Total Payment :</strong>
                      </td>
                      <td className="text-align-right text-right">
                        <strong>{`${currencyAmount(totalAmount)} LKR`}</strong>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {userPayments?.length > 0 && (
            <div className="mt-4">
              <strong style={{ marginBottom: "5px" }}>Payment Details</strong>
              <table className="order-table" style={{ marginTop: "8px" }}>
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Remark</th>
                    <th className="updated" style={{ width: "140px" }}>
                      Updated
                    </th>
                    <th className="updated-by" style={{ width: "180px" }}>
                      Updated By
                    </th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {userPayments?.map((userPayment) => (
                    <tr key={userPayment.id}>
                      <td>{userPayment.userName}</td>

                      <td>{userPayment.remark}</td>
                      <td>{formatTableDate(userPayment.updated)}</td>
                      <td>{userPayment.updatedByName}</td>
                      <td className="text-align-right text-right">{`${currencyAmount(
                        userPayment.amount
                      )} LKR`}</td>
                    </tr>
                  ))}
                  {userPayments && userPayments.length > 0 && (
                    <tr>
                      <td colSpan="4">
                        <strong>Total Payments :</strong>
                      </td>
                      <td className="text-align-right text-right">
                        <strong>{`${currencyAmount(
                          totalPayments
                        )} LKR`}</strong>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ContributionReport;
