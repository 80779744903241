import React from "react";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

const Loader = ({ showLoader }) => {
  return (
    <Dialog
      visible={showLoader}
      showHeader={false}
      modal={false}
      contentStyle={{
        borderRadius: "0.7rem",
        width: "10rem",
        height: "10rem",
      }}
    >
      <div className="flex flex-column align-items-center mt-5">
        <ProgressSpinner
          className="w-3rem h-3rem animation-duration-4000"
          strokeWidth="4"
        />
        <strong className="p-3">Loading...</strong>
      </div>
    </Dialog>
  );
};

export default Loader;
