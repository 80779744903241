import React, { useEffect, useState, useRef } from "react";

import axios from "axios";

import printJS from "print-js";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";

import { currencyAmount } from "../../../helpers/TemplateHelper";

function FixingTransportationReport() {
  const printStyles = `
    body {font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; }
    label, div {font-size: 11px}
    strong {font-size: 10px}
    h4 {font-size: 13px}
    table, td, th {border: 0.5px groove ; font-size: 11px; padding: 4px} 
    table {width: 100%; border-collapse: collapse; margin-bottom: 10px}
    th {font-weight: 400; text-align: left, padding: 4px}
    .updated-by {width: 50px !important}
    .text-align-right {text-align: right}
    .text-align-left {text-align: left}
    .border-none td {border: none; font-size: 11px; padding: 1px}`;

  const toast = useRef(null);

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  const currentDate = new Date();

  const [fixingTransData, setFixingTransData] = useState(null);

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  useEffect(() => {
    fetchData();
    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const firstName = decodedPayload.firstName;
      const lastName = decodedPayload.lastName;
      setUserFirstName(firstName);
      setUserLastName(lastName);
    }
  }, []);

  const fetchData = () => {
    if (fromDate && toDate) {
      const from = fromDate?.toISOString();
      const to = toDate?.toISOString();

      axios
        .get(`/api/reports/fixing-transportation/${from}/${to}`)
        .then((res) => {
          const fetchedData = res.data;

          const processedData = fetchedData.map((order) => {
            const sortedPayments = order.payments.sort(
              (a, b) => new Date(a.updated) - new Date(b.updated)
            );

            const billNo =
              sortedPayments.length > 0 ? sortedPayments[0].referenceNo : null;

            return {
              ...order,
              billNo,
            };
          });

          setFixingTransData(processedData);
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occurred while fetching fixing & transportation data.",
            life: 3000,
          });
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please select date range",
        life: 3000,
      });
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const formattedDate = `${formatDate(currentDate)} - ${formatTime(
    currentDate
  )}`;

  function formatTableDate(dateString) {
    const options = {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
    };

    return new Date(dateString).toLocaleDateString("en-US", options);
  }

  const getTotal = () => {
    let totalDistance = 0;
    let totalSets = 0;
    let totalFixingCost = 0;
    let totalTransportationCost = 0;

    if (fixingTransData && fixingTransData.length > 0) {
      fixingTransData.forEach((record) => {
        totalDistance += parseFloat(record?.orderDetails?.distance) || 0;
        totalSets += parseInt(record?.orderDetails?.totalQty) || 0;
        totalFixingCost += parseFloat(record?.orderDetails?.fixingCost) || 0;
        totalTransportationCost +=
          parseFloat(record?.orderDetails?.transportationCost) || 0;
      });
    }

    return {
      totalDistance,
      totalSets,
      totalFixingCost,
      totalTransportationCost,
    };
  };

  const { totalDistance, totalSets, totalFixingCost, totalTransportationCost } =
    getTotal();

  const handlePrint = () => {
    printJS({
      printable: "fixing-transportation-report",
      type: "html",
      scanStyles: false,
      style: printStyles,
    });
  };

  return (
    <div className="container">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between">
        <div>
          <h4 className="text-primary mb-1">Fixing & Transportation Report</h4>
          <label className="text-secondary">
            View fixing & transportation report and print
          </label>
        </div>
        <div className="flex gap-2 align-items-center flex-wrap">
          <span className="filter-label">From Date : </span>
          <Calendar
            value={fromDate}
            onChange={(e) => setFromDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <span className="filter-label">To Date : </span>
          <Calendar
            value={toDate}
            onChange={(e) => setToDate(e.value)}
            showButtonBar
            dateFormat="yy-mm-dd"
          />
          <Button
            style={{ height: "33px" }}
            label="Refresh Data"
            severity="warning"
            onClick={() => fetchData()}
          />
          <Button
            style={{ height: "33px" }}
            label="Print Report"
            icon="pi pi-print"
            onClick={handlePrint}
          />
        </div>
      </div>
      {fixingTransData && fromDate && toDate && fixingTransData.length > 0 && (
        <div
          id="fixing-transportation-report"
          style={{ fontSize: "11px !important", marginTop: "8px" }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: "200px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="report-header">
                    <h4>Fixing & Transportation Report</h4>
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <div>Created: {formattedDate}</div>
                    <div>
                      Created By: {userFirstName} {""} {userLastName}{" "}
                    </div>
                    <div>
                      From {fromDate && formatDate(fromDate)} to{" "}
                      {toDate && formatDate(toDate)}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: "18px",
                    }}
                  >
                    <img
                      src={require("../../../images/logo.png")}
                      alt="logo"
                      style={{ height: "50px" }}
                    />
                  </div>
                  <div
                    className="text-align-right text-right"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>RAGAMA - WALPOLA - WELIMADA - NUWARAELIYA</label>
                    <label>Email : geethcurtains@gmail.com</label>
                    <label>Phone : 0718021976 - 0771786299</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="fixingTransSummary"
            className="flex flex-column flex-wrap summary"
            style={{
              marginTop: "10px",
            }}
          >
            <div
              id="FixingTransSummary"
              className="fixingTrans-summary mb-5 flex flex-column flex-wrap summary"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <table className="order-table">
                <thead>
                  <tr>
                    <th className="text-align-left text-left" colSpan="2">
                      <strong>Fixing & Transportation Summary</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Fixing Cost :</td>

                    <td className="text-align-right text-right">
                      {`${currencyAmount(totalFixingCost)} LKR`}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Transportation Cost:</td>
                    <td className="text-align-right text-right">
                      {`${currencyAmount(totalTransportationCost)} LKR`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table className="order-table" style={{ minWidth: "950px" }}>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Order Id</th>
                  <th>Bill No</th>
                  <th>Customer Name</th>
                  <th>Last Name</th>
                  <th className="updated-by" style={{ width: "50px" }}>
                    Updated
                  </th>

                  <th>Distance (Km)</th>
                  <th>No of Sets</th>
                  <th>Fixing Cost</th>
                  <th>Transportation Cost</th>
                </tr>
              </thead>
              <tbody>
                {fixingTransData.map((record, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{record?.id.toString().padStart(8, "0")}</td>
                    <td>{record?.billNo}</td>
                    <td>{record?.customerDetails?.firstName}</td>
                    <td>{record?.customerDetails?.lastName}</td>
                    <td>{formatTableDate(record.updated)}</td>
                    <td className="text-align-right text-right">
                      {record?.orderDetails?.distance}
                    </td>
                    <td className="text-align-right text-right">
                      {record?.orderDetails?.totalQty}
                    </td>
                    <td className="text-align-right text-right">
                      {`${currencyAmount(
                        record?.orderDetails?.fixingCost
                      )} LKR`}
                    </td>
                    <td className="text-align-right text-right">
                      {`${currencyAmount(
                        record?.orderDetails?.transportationCost
                      )} LKR`}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="6">
                    <strong>Total</strong>
                  </td>
                  <td className="text-align-right text-right">
                    <strong>{totalDistance}</strong>
                  </td>
                  <td className="text-align-right text-right">
                    <strong>{totalSets}</strong>
                  </td>
                  <td className="text-align-right text-right">
                    <strong>{`${currencyAmount(totalFixingCost)} LKR`}</strong>
                  </td>
                  <td className="text-align-right text-right">
                    <strong>{`${currencyAmount(
                      totalTransportationCost
                    )} LKR`}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default FixingTransportationReport;
