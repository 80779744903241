import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";

const formData = {
  id: 0,
  accountNo: "",
  supplier: "",
  transactionType: "",
  amount: null,
  remark: "",
  date: null,
};

const transactionTypeOptions = ["Payment", "Purchasing"];

const DebtorsDialog = ({ debtors, onCancel, onSubmit }) => {
  const [form, setForm] = useState({});
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [accountNoOptions, setAccountNoOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const toast = useRef(null);

  useEffect(() => {
    setForm(debtors || { ...formData });
  }, [debtors]);

  useEffect(() => {
    axios
      .get("/api/keyValuePairs/getByKey/supplier")
      .then((response) => {
        const supplierOptions = response.data.map((supplier) => ({
          label: supplier.value,
          value: supplier.value,
        }));
        setSupplierOptions(supplierOptions);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Supplier Error",
          detail:
            "An error occurred while fetching suppliers. Please try again.",
          life: 3000,
        });
      });

    axios
      .get("/api/keyValuePairs/getByKey/account")
      .then((response) => {
        const accountOptions = response.data.map((account) => ({
          label: account.value,
          value: account.value,
        }));
        setAccountNoOptions(accountOptions);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Supplier Error",
          detail:
            "An error occurred while fetching accounts. Please try again.",
          life: 3000,
        });
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.accountNo) {
      newErrors.accountNo = "Account No is required";
    }
    if (!form.supplier) {
      newErrors.supplier = "Supplier is required";
    }
    if (!form.transactionType) {
      newErrors.transactionType = "Transaction Type is required";
    }
    if (!form.amount) {
      newErrors.amount = "Amount is required";
    }
    if (!form.date) {
      newErrors.date = "Date is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const result = await axios.post("api/Debtors", form);

        if (result.data?.id) {
          toast.current.show({
            severity: "success",
            summary: "Debtor Added",
            detail: "Debtor has been successfully added.",
            life: 3000,
          });
          setForm({ ...formData });
          onSubmit();
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Debtor Error",
          detail: "An error occurred while adding debtor. Please try again.",
          life: 3000,
        });
      }
    }
  };

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="accountNo">Account No *</label>
          <Dropdown
            id="accountNo"
            name="accountNo"
            options={accountNoOptions}
            value={form.accountNo || ""}
            onChange={handleChange}
            disabled={form.transactionType === "Debt"}
            placeholder="Select an Account No"
          />
          {errors.accountNo && (
            <label className="p-error">{errors.accountNo}</label>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="supplier">Supplier *</label>
          <Dropdown
            id="supplier"
            name="supplier"
            options={supplierOptions}
            value={form.supplier || ""}
            onChange={handleChange}
            placeholder="Select a Supplier"
          />
          {errors.supplier && (
            <label className="p-error">{errors.supplier}</label>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="transactionType">Transaction Type *</label>
          <Dropdown
            id="transactionType"
            name="transactionType"
            options={transactionTypeOptions}
            value={form.transactionType || ""}
            onChange={handleChange}
            placeholder="Select Transaction Type"
          />
          {errors.transactionType && (
            <label className="p-error">{errors.transactionType}</label>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="amount">Amount *</label>
          <InputNumber
            inputId="amount"
            name="amount"
            value={form.amount || ""}
            onValueChange={(e) => handleChange(e)}
            minFractionDigits={2}
            prefix="LKR "
            locale="en-LK"
            autoComplete="off"
          />
          {errors.amount && <label className="p-error">{errors.amount}</label>}
        </div>
        <div className="p-field">
          <label htmlFor="date">Date *</label>
          <Calendar
            id="date"
            name="date"
            value={form.date || null}
            onChange={(e) => setForm({ ...form, date: e.value })}
            dateFormat="dd/mm/yy"
            showButtonBar={true}
          />
          {errors.date && <label className="p-error">{errors.date}</label>}
        </div>
        <div className="p-field">
          <label htmlFor="remark">Remark</label>
          <InputTextarea
            id="remark"
            name="remark"
            rows={4}
            value={form.remark || ""}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </div>
  );
};

export default DebtorsDialog;
