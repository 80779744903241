import { React, useState, useEffect, useRef } from "react";

import { useParams, Link } from "react-router-dom";

import axios from "axios";

import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { DataView } from "primereact/dataview";
import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";

import AddRemark from "../components/AddRemark";
import AddOnTable from "../components/addon-component/AddOnTable";
import AddOnDialog from "../components/addon-component/AddOnDialog";
import Event from "../components/event-component/Event";
import EventTable from "../components/event-component/EventTable";
import PaymentsTable from "../components/payment-component/PaymentsTable";
import PaymentDialog from "../components/payment-component/PaymentDialog";
import PaymentSummaryView from "../components/payment-component/PaymentSummaryView";
import ContributionDialog from "../components/contribution-component/ContributionDialog";
import ContributionsTable from "../components/contribution-component/ContributionsTable";
import { currencyAmount } from "../../helpers/TemplateHelper";
import CurrencyConversionDialog from "../components/CurrencyConversionDialog";

const Order = () => {
  const toast = useRef(null);
  const { id } = useParams();

  const [order, setOrder] = useState(null);

  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [showCurrencyConversionDialog, setShowCurrencyConversionDialog] =
    useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [showContributionDialog, setShowContributionDialog] = useState(false);
  const [showAddOnDialog, setShowAddOnDialog] = useState(false);
  const [showEventDialog, setShowEventDialog] = useState(false);
  const [showRemarkDialog, setShowRemarkDialog] = useState(false);

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.get(
        `api/CurrencyConversion/download/${order.id}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Invoice(${order.id}).pdf`);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while downloading file. Please try again.",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  async function fetchOrder() {
    try {
      const res = await axios.get(`/api/orders/${id}`);
      if (res.data?.id) {
        setOrder(res.data);
      }
    } catch {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while getting data. Please try again.",
        life: 3000,
      });
    }
  }

  const handleDelete = (orderItem) => {
    confirmDialog({
      message: "Are you sure you want to delete this remark?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => handleDeleteRemark(orderItem),
    });
  };

  const handleDeleteRemark = async (orderItem) => {
    try {
      await axios.delete(`/api/remark/${orderItem.id}`);
      fetchOrder();
      toast.current.show({
        severity: "success",
        summary: "Remark Deleted",
        detail: "The remark has been successfully deleted.",
        life: 3000,
      });
    } catch {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "An error occurred while deleting the remark. Please try again.",
        life: 3000,
      });
    }
  };

  const calculateTotalAddOnCost = () => {
    if (order?.addOns) {
      return order.addOns.reduce((total, addOn) => total + addOn.amount, 0);
    }
    return 0;
  };

  const calculateDiscountTotal = () => {
    let discountTotal = 0;

    if (order?.addOns) {
      order?.addOns.forEach((addOn) => {
        if (addOn.type === "discounts") {
          discountTotal += addOn.amount;
        }
      });
    }

    return discountTotal;
  };

  const discountTotal = calculateDiscountTotal();
  const otherAddonsTotal = calculateTotalAddOnCost() - discountTotal;

  const calculateSubTotal = () => {
    let subTotal = 0;

    if (order) {
      subTotal =
        (order.orderDetails?.subTotal || 0) +
        (order.orderDetails?.transportationCost || 0);
    }
    return subTotal;
  };

  const calculateTotal = () => {
    let total = 0;

    if (order) {
      total =
        calculateSubTotal() +
        (order.orderDetails?.fixingCost || 0) +
        otherAddonsTotal;
    }

    return total;
  };

  const totalReceivedPayment = order?.payments?.reduce(
    (total, payment) => total + payment.amount,
    0
  );

  const remainingPayment =
    calculateTotal() - (discountTotal + totalReceivedPayment);

  const handleRefresh = () => {
    fetchOrder();
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const handleShowRemarkDialog = (orderItem) => {
    setSelectedOrderItem(orderItem);
    setShowRemarkDialog(true);
  };

  const handleCompleteOrder = () => {
    confirmDialog({
      message: "Are you sure you want to complete this order?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await axios.post(`/api/orders/complete/${id}`);

          window.location.href = "pages/completed";
        } catch {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error occurred while completing the order. Please try again.",
            life: 3000,
          });
        }
      },
    });
  };

  const handleOrderStatus = () => {
    if (order) {
      switch (order?.status) {
        case 0:
          return (
            <Tag
              className="mt-1 ml-1"
              style={{ height: "1.5rem" }}
              severity="info"
              value="Pending"
            />
          );
        case 1:
          return (
            <Tag
              className="mt-1 ml-1"
              style={{ height: "1.5rem" }}
              severity="success"
              value="Accepted"
            />
          );
        case 2:
          return (
            <Tag
              className="mt-1 ml-1"
              style={{ height: "1.5rem" }}
              severity="danger"
              value="Rejected"
            />
          );
        case 3:
          return (
            <Tag
              className="mt-1 ml-1"
              style={{ height: "1.5rem" }}
              severity="success"
              value="Completed"
            />
          );
        default:
          return (
            <Tag
              className="mt-1 ml-1"
              style={{ height: "1.5rem" }}
              severity="default"
              value="Unknown"
            />
          );
      }
    }
    return null;
  };

  const handleButtonsDiv = () => {
    if (order?.status === 0 || order?.status === 2 || order?.status === 3) {
      return (
        <div className="flex flex-row gap-2 flex-wrap h-1rem">
          <Link to={`/pages/reports/order-wise-report/${id}`}>
            <Button
              icon="pi pi-print"
              className="p-button-success"
              label="Print"
            ></Button>
          </Link>
          <Button
            label="Refresh Data"
            severity="warning"
            onClick={handleRefresh}
          />
        </div>
      );
    }

    return (
      <div
        className="flex flex-row gap-2 flex-wrap h-1rem"
        style={{ justifyContent: "end" }}
      >
        <Button
          className="p-button-primary"
          icon="pi pi-plus"
          label="Add Currency Conversion"
          onClick={() => setShowCurrencyConversionDialog(true)}
        />
        <Button
          className="p-button-primary"
          icon="pi pi-plus"
          label="Add Contribution"
          onClick={() => setShowContributionDialog(true)}
        />
        <Button
          className="p-button-primary"
          label="Add Payment"
          icon="pi pi-plus"
          onClick={() => setShowPaymentDialog(true)}
        />
        <Button
          className="p-button-primary"
          label="Add-Ons"
          icon="pi pi-plus"
          severity="primary"
          onClick={() => setShowAddOnDialog(true)}
        />
        <Button
          className="p-button-primary"
          label="Timeline"
          icon="pi pi-calendar"
          severity="primary"
          onClick={() => setShowEventDialog(true)}
        />

        <Button
          className="p-button-success"
          label="Complete Order"
          icon="pi pi-check"
          onClick={handleCompleteOrder}
        />
        <Link to={`/pages/reports/order-wise-report/${id}`}>
          <Button
            icon="pi pi-print"
            className="p-button-success"
            label="Print"
          ></Button>
        </Link>
        <Button
          label="Refresh Data"
          severity="warning"
          onClick={handleRefresh}
        />
      </div>
    );
  };

  const handleRemarkDiv = (item) => {
    if (order?.status === 0 || order?.status === 2 || order?.status === 3) {
      return null;
    }
    return (
      <div
        className={`flex justify-content-between mb-2 p-2 ${
          item.remark ? "remark-style" : ""
        }`}
      >
        <Button
          label={item.remark ? "Edit Remark" : "Add Remark"}
          icon="pi pi-comment"
          onClick={() => handleShowRemarkDialog(item)}
          className="p-button-info"
        />
        {item.remark ? (
          <div className="flex align-items-center">
            <div>{item.remark}</div>
            <Button
              text
              icon="pi pi-times"
              onClick={() => handleDelete(item)}
              tooltip="Remove Remark"
            />
          </div>
        ) : null}
      </div>
    );
  };

  const handleClear = () => {
    setOrder({});
  };

  const handlePolesWidth =
    order?.orderItems?.some((item) => item.poleRequired) || false;

  const itemTemplate = (item) => {
    return (
      <div className="item-container">
        <div className="flex data-view-item">
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/geethcuratins.appspot.com/o/${item.image}?alt=media&token=debc5e8a-2c21-45a3-b0d6-50de7f8166d7`}
            alt={item.image}
          />
          <div>
            <div>
              <strong>
                {item?.selectedDesign.description} / {item?.selectedDesign.code}
              </strong>
              <strong>
                {item.type} / {item.poleRequired ? "With Pole" : "Without Pole"}
              </strong>
            </div>
            <div>
              <label>Dimensions</label>
              <label>
                Width :{item.width} Inches / Height : {item.height} Inches
              </label>
            </div>
            <div>
              <label>No. of Pleats :</label>
              <label>{item.pleats} Pleats</label>
            </div>
            <div>
              <label>Quantity :</label>
              <label>{item.quantity} Set(s)</label>
            </div>
            <div>
              <label>Unit Price :</label>
              <label>{`${currencyAmount(item.cost)} LKR`}</label>
            </div>
            <div>
              <strong>Total :</strong>
              <strong>{`${currencyAmount(item.total)} LKR`}</strong>
            </div>
          </div>
        </div>
        <div>{handleRemarkDiv(item)}</div>
      </div>
    );
  };

  return (
    <div className="container">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between gap-2">
        <div style={{ minWidth: "170px" }}>
          <div className="flex flex-row flex-wrap justify-content-between">
            <h4 className="text-primary mb-2">Order Details</h4>
            {handleOrderStatus()}
          </div>
          <h6 className="m-0">
            {id} / {order?.created ? formatDate(order.created) : ""}
            {" - "}
            {order?.created ? formatTime(order.created) : ""}
          </h6>
        </div>
        {handleButtonsDiv()}
      </div>
      <div className="flex justify-content-between mt-4">
        <div className="flex flex-column gap-2 flex-wrap w-full mt-4 mr-8">
          <h5 className="text-color-secondary">Customer Details</h5>
          <strong>
            Name : {order?.customerDetails?.firstName}{" "}
            {order?.customerDetails?.lastName}
          </strong>
          <label className="block">
            Email : {order?.customerDetails?.email}
          </label>
          <label className="block">
            Contact No : {order?.customerDetails?.telephone}
          </label>
          <p className="block">
            Address : {order?.customerDetails?.addressLine1}{" "}
            {order?.customerDetails?.addressLine2}
          </p>
          <Divider></Divider>
          <PaymentSummaryView
            order={order}
            totalReceivedPayment={totalReceivedPayment}
            remainingPayment={remainingPayment}
            calculateTotal={calculateTotal}
            calculateDiscountTotal={calculateDiscountTotal}
          />
        </div>

        {order?.currencyConversion ? (
          <div className="flex flex-column gap-2 flex-wrap w-full mt-4 mr-8">
            <h5 className="text-color-secondary">
              Currency Conversion Details
            </h5>
            <strong>Currency : {order?.currencyConversion?.currency}</strong>
            <div className="flex justify-content-between">
              <label>Currency Rate :</label>
              <label>
                {currencyAmount(order?.currencyConversion?.currencyRate)}{" "}
                {" LKR"}
              </label>
            </div>
            <div className="flex justify-content-between">
              <label> Amount :</label>
              <label>
                {currencyAmount(order?.currencyConversion?.amount)}{" "}
                {order?.currencyConversion?.currency}
              </label>
            </div>
            <div className="flex justify-content-between">
              <label>Delivery Charge :</label>
              <label>
                {currencyAmount(order?.currencyConversion?.deliveryCharge)}{" "}
                {order?.currencyConversion?.currency}
              </label>
            </div>
            <div className="flex justify-content-between font-bold">
              <label>Total :</label>
              <label>
                {currencyAmount(order?.currencyConversion?.total)}
                {" LKR"}
              </label>
            </div>
            <div className="flex justify-content-between">
              <Button
                icon="pi pi-download"
                className="p-button-info"
                label="Download PDF"
                onClick={handleDownloadPDF}
              ></Button>
              <Button
                icon="pi pi-clear"
                className="p-button-warning"
                label="Clear"
                onClick={handleClear}
              ></Button>
            </div>
          </div>
        ) : null}

        <div className="flex flex-column gap-1 flex-wrap summary w-full p-4">
          <h5 className="text-color-secondary">Order Summary</h5>
          <div>
            <label>Total Quantity :</label>
            <label>{order?.orderDetails?.totalQty}</label>
          </div>
          <div>
            <label>Total Pleats :</label>
            <label>{order?.orderDetails?.totalPleats}</label>
          </div>
          {handlePolesWidth && (
            <div>
              <label>Total Poles Width :</label>
              <label>{order?.orderDetails?.totalWidth} F</label>
            </div>
          )}
          <div>
            <label>Distance :</label>
            <label>{order?.orderDetails?.distance} km(s)</label>
          </div>
          <Divider></Divider>
          <div>
            <label>Fixing Cost :</label>
            <label>{`${currencyAmount(
              order?.orderDetails?.fixingCost
            )} LKR`}</label>
          </div>
          <div>
            <label>Transportation Cost :</label>
            <label>
              {`${currencyAmount(order?.orderDetails?.transportationCost)} LKR`}
            </label>
          </div>

          <div>
            <label>Total AddOns Cost :</label>
            <label>{`${currencyAmount(otherAddonsTotal)} LKR`}</label>
          </div>
          <div>
            <label>Sub Total :</label>
            <label>{`${currencyAmount(calculateSubTotal())} LKR`}</label>
          </div>
          <div>
            <strong>Total :</strong>
            <strong>{`${currencyAmount(calculateTotal())} LKR`}</strong>
          </div>
        </div>
      </div>
      <DataView
        className="pt-5"
        value={order?.orderItems}
        onChange={(e) => setOrder(e.value)}
        itemTemplate={itemTemplate}
        header="Order Items"
      ></DataView>
      <AddRemark
        visible={showRemarkDialog}
        toastrRef={toast}
        orderItem={selectedOrderItem}
        onHide={() => setShowRemarkDialog(false)}
        onChange={() => {
          setShowRemarkDialog(false);
          fetchOrder();
        }}
      />
      <AddOnDialog
        visible={showAddOnDialog}
        onCancel={() => setShowAddOnDialog(false)}
        orderId={id}
        toastrRef={toast}
        onAddOnAdded={() => {
          setShowAddOnDialog(false);
          fetchOrder();
        }}
      />
      {order?.addOns && order.addOns.length > 0 && (
        <AddOnTable
          orderId={id}
          toastrRef={toast}
          addons={order?.addOns}
          orderStatus={order?.status}
          onChange={() => fetchOrder()}
        />
      )}
      <PaymentDialog
        visible={showPaymentDialog}
        toastrRef={toast}
        onCancel={() => setShowPaymentDialog(false)}
        orderId={id}
        onPaymentAdded={() => {
          setShowPaymentDialog(false);
          fetchOrder();
        }}
      />
      {order?.payments && order.payments.length > 0 && (
        <PaymentsTable
          orderId={id}
          toastrRef={toast}
          payments={order?.payments}
          orderStatus={order?.status}
          onChange={() => fetchOrder()}
        />
      )}
      <CurrencyConversionDialog
        visible={showCurrencyConversionDialog}
        toastrRef={toast}
        onCancel={() => setShowCurrencyConversionDialog(false)}
        orderId={id}
        onCurrencyConversionAdded={() => {
          setShowCurrencyConversionDialog(false);
          fetchOrder();
        }}
      />
      <ContributionDialog
        visible={showContributionDialog}
        toastrRef={toast}
        onCancel={() => setShowContributionDialog(false)}
        orderId={id}
        onContributionAdded={() => {
          setShowContributionDialog(false);
          fetchOrder();
        }}
      />
      {order?.contributions && order.contributions.length > 0 && (
        <ContributionsTable
          orderId={id}
          toastrRef={toast}
          contributions={order?.contributions}
          orderStatus={order?.status}
          onChange={() => fetchOrder()}
        />
      )}
      <Event
        visible={showEventDialog}
        toastrRef={toast}
        onCancel={() => setShowEventDialog(false)}
        orderId={id}
        onEventAdded={() => {
          setShowEventDialog(false);
          fetchOrder();
        }}
      />
      {order?.events && order.events.length > 0 && (
        <EventTable
          orderId={id}
          events={order?.events}
          orderStatus={order?.status}
          toastrRef={toast}
          onChange={() => fetchOrder()}
        />
      )}
    </div>
  );
};

export default Order;
