import React, { useEffect, useState } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";

const formData = {
  id: 0,
  branch: "",
  category: "",
  amount: null,
  remark: "",
  updated: new Date(),
};

const ExpenseDialog = ({ onCancel, onSubmit, toast }) => {
  const [form, setForm] = useState({ ...formData });
  const [categoryIdOptions, setCategoryIdOptions] = useState([]);
  const [branchIdOptions, setBranchIdOptions] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    axios
      .get("/api/keyValuePairs/getByKey/expense")
      .then((response) => {
        const categoryIdOptions = response.data.map((category) => ({
          label: category.value,
          value: category.value,
        }));
        setCategoryIdOptions(categoryIdOptions);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Expense Error",
          detail: "An error occurred while fetching expense. Please try again.",
          life: 3000,
        });
      });

    axios
      .get("/api/keyValuePairs/getByKey/branch")
      .then((response) => {
        const branchIdOptions = response.data.map((branch) => ({
          label: branch.value,
          value: branch.value,
        }));
        setBranchIdOptions(branchIdOptions);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Branch Error",
          detail: "An error occurred while fetching branch. Please try again.",
          life: 3000,
        });
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.branch) newErrors.branch = "Branch is required";
    if (!form.category) newErrors.category = "Category is required";
    if (!form.amount) newErrors.amount = "Amount is required";
    if (!form.updated) newErrors.updated = "Updated date is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const result = await axios.post("api/Expenses", {
          ...form,
          updated: form.updated.toISOString(),
        });

        if (result.data?.id) {
          toast.current.show({
            severity: "success",
            summary: "Expense Added",
            detail: "Expense has been successfully added.",
            life: 3000,
          });
          setForm({ ...formData });
          onSubmit();
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Expense Error",
          detail: "An error occurred while adding expense. Please try again.",
          life: 3000,
        });
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="branchId">Branch *</label>
          <Dropdown
            id="branchId"
            name="branch"
            options={branchIdOptions}
            value={form.branch || ""}
            onChange={handleChange}
            placeholder="Select a Branch"
          />
          {errors.branch && <label className="p-error">{errors.branch}</label>}
        </div>
        <div className="p-field">
          <label htmlFor="categoryId">Category *</label>
          <Dropdown
            id="categoryId"
            name="category"
            options={categoryIdOptions}
            value={form.category || ""}
            onChange={handleChange}
            placeholder="Select Category"
          />
          {errors.category && (
            <label className="p-error">{errors.category}</label>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="amount">Amount *</label>
          <InputNumber
            inputId="amount"
            name="amount"
            value={form?.amount || null}
            onValueChange={(e) => handleChange(e)}
            minFractionDigits={2}
            locale="en-LK"
            autoComplete="off"
          />
          {errors.amount && <label className="p-error">{errors.amount}</label>}
        </div>
        <div className="p-field">
          <label htmlFor="remark">Remark</label>
          <InputTextarea
            id="remark"
            name="remark"
            rows={4}
            value={form.remark || ""}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="p-field">
          <label htmlFor="updated">Updated *</label>
          <Calendar
            id="updated"
            name="updated"
            value={form.updated}
            onChange={(e) => setForm({ ...form, updated: e.value })}
            showIcon
            showTime
            hourFormat="24"
            dateFormat="yy-mm-dd"
          />
          {errors.updated && (
            <label className="p-error">{errors.updated}</label>
          )}
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </div>
  );
};

export default ExpenseDialog;
