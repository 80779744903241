import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";

const formData = {
  id: 0,
  type: "",
  measureUnit: "",
  unitPrice: null,
};

const ContributionTypesDialog = ({ contributionType, onCancel, onSubmit }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const toast = useRef(null);

  useEffect(() => {
    setForm(contributionType || { ...formData });
  }, [contributionType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.type) {
      newErrors.type = "Contribution Type is required";
    }
    if (!form.measureUnit) {
      newErrors.measureUnit = "Measure Unit is required";
    }
    if (!form.unitPrice) {
      newErrors.unitPrice = "Unit Price is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let result = await axios.post("api/contributionTypes", form);

        if (result.data?.id) {
          toast.current.show({
            severity: "success",
            summary: "Contribution type Added",
            detail: "Contribution type has been successfully added.",
            life: 3000,
          });
          setForm({ ...formData });
          onSubmit();
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Contribution type Error",
          detail:
            "An error occurred while adding contribution type. Please try again.",
          life: 3000,
        });
      }
    }
  };

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="contributionType">Contribution Type *</label>
          <InputText
            id="type"
            name="type"
            value={form.type || ""}
            onChange={handleChange}
            autoComplete="off"
          />
          {errors.type && <label className="p-error">{errors.type}</label>}
        </div>
        <div className="p-field">
          <label htmlFor="measureUnit">Measure Unit *</label>
          <InputText
            id="measureUnit"
            name="measureUnit"
            value={form?.measureUnit || ""}
            onChange={handleChange}
            autoComplete="off"
            placeholder="Ex: per day"
          />
          {errors.measureUnit && (
            <label className="p-error">{errors.measureUnit}</label>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="unitPrice">Unit Price *</label>
          <InputNumber
            inputId="unitPrice"
            name="unitPrice"
            value={form?.unitPrice || ""}
            onValueChange={(e) => handleChange(e)}
            minFractionDigits={2}
            prefix="LKR "
            locale="en-LK"
            autoComplete="off"
          />
          {errors.unitPrice && (
            <label className="p-error">{errors.unitPrice}</label>
          )}
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </div>
  );
};

export default ContributionTypesDialog;
