import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import Layout from "./layout/layout";

import { LayoutProvider } from "./layout/context/layoutcontext";

import "./styles/custom.scss";
import "./styles/layout/layout.scss";

import axios from "axios";

import Login from "./Login";
import Completed from "./layout/pages/Completed";
import ContributionTypes from "./layout/pages/ContributionTypes";
import Dashboard from "./layout/pages/Dashboard";
import Expenses from "./layout/pages/Expenses";
import KeyValuePairs from "./layout/pages/KeyValuePairs";
import Order from "./layout/pages/Order";
import Orders from "./layout/pages/Orders";
import Quotations from "./layout/pages/Quotations";
import RejectOrders from "./layout/pages/RejectOrders";
import ContributionReport from "./layout/pages/Reports/Contribution-Report";
import FixingTransportationReport from "./layout/pages/Reports/Fixing-Transportation-Report";
import IncomeExpensesReport from "./layout/pages/Reports/Income-Expenses-Report";
import OrderwiseReport from "./layout/pages/Reports/Order-Wise-Report";
import PoleRequirementReport from "./layout/pages/Reports/Pole-Requirement-Report";
import ToDo from "./layout/pages/ToDo";
import User from "./layout/pages/User";
import UserPayments from "./layout/pages/UserPayments";
import Users from "./layout/pages/Users";
import Debtors from "./layout/pages/debtors";
import GoodsReceive from "./layout/pages/stocks/GoodReceive";
import ItemUsage from "./layout/pages/stocks/ItemUsage";
import Items from "./layout/pages/stocks/Items";
import ShortagesExcesses from "./layout/pages/stocks/ShortagesExcesses";
import StockViewer from "./layout/pages/stocks/StocksViewer";
import Loader from "./loader-service/Loader";

export default function App() {
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate(`/login`);
    }

    axios.interceptors.request.use(
      (config) => {
        setShowLoader(true);
        return config;
      },
      (error) => {
        setShowLoader(false);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        setShowLoader(false);
        return response;
      },
      (error) => {
        setShowLoader(false);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
        return Promise.reject(error);
      }
    );
  }, [navigate]);

  return (
    <LayoutProvider>
      <Layout>
        <Loader showLoader={showLoader} />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/quotations" element={<Quotations />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/rejectOrders" element={<RejectOrders />} />
          <Route path="/order/:id" element={<Order />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/user/:id" element={<User />} />
          <Route
            exact
            path="/contributionTypes"
            element={<ContributionTypes />}
          />
          <Route
            exact
            path="/reports/order-wise-report/:id"
            element={<OrderwiseReport />}
          />
          <Route
            exact
            path="/reports/contribution-report"
            element={<ContributionReport />}
          />
          <Route exact path="/completed" element={<Completed />} />
          <Route exact path="/userPayments" element={<UserPayments />} />
          <Route exact path="/expenses" element={<Expenses />} />
          <Route exact path="/to-do" element={<ToDo />} />
          <Route
            exact
            path="/reports/income-report"
            element={<IncomeExpensesReport />}
          />
          <Route
            exact
            path="/reports/pole-requirement-report"
            element={<PoleRequirementReport />}
          />
          <Route
            exact
            path="/reports/fixing-transportation-report"
            element={<FixingTransportationReport />}
          />
          <Route exact path="/keyValues" element={<KeyValuePairs />} />
          <Route exact path="/items" element={<Items />} />
          <Route exact path="/stocks" element={<StockViewer />} />
          <Route exact path="/good-receive" element={<GoodsReceive />} />
          <Route exact path="/item-usage" element={<ItemUsage />} />
          <Route
            exact
            path="/shortage-excess"
            element={<ShortagesExcesses />}
          />
          <Route exact path="/debtors-list" element={<Debtors />} />
        </Routes>
      </Layout>
    </LayoutProvider>
  );
}
