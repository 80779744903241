import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";

const formData = {
  id: 0,
  name: "",
  category: "",
  code: "",
  measureUnit: "",
  reOrderLevel: null,
  description: "",
};

const ItemDialog = ({ item, onCancel, onSubmit }) => {
  const [form, setForm] = useState({ ...formData });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const toast = useRef(null);

  useEffect(() => {
    setForm(item || { ...formData });
    fetchCategoryOptions();
  }, [item]);

  const fetchCategoryOptions = () => {
    axios
      .get("/api/keyValuePairs/getByKey/itemCategories")
      .then((response) => {
        const categoryIdOptions = response.data.map((category) => ({
          label: category.value,
          value: category.value,
        }));
        setCategoryOptions(categoryIdOptions);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch item categories. Please try again.",
          life: 3000,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.category) {
      newErrors.category = "Category is required";
    }
    if (!form.name) {
      newErrors.name = "Item name is required";
    }
    if (!form.code) {
      newErrors.code = "Item code is required";
    }
    if (!form.measureUnit) {
      newErrors.measureUnit = "Measure unit is required";
    }
    // Add more validation as needed

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const result = await axios.post("/api/items", form);

        if (result.data?.id) {
          toast.current.show({
            severity: "success",
            summary: "Item Added",
            detail: "Item has been successfully added.",
            life: 3000,
          });
          setForm({ ...formData });
          onSubmit();
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Item Error",
          detail: "An error occurred while adding item. Please try again.",
          life: 3000,
        });
      }
    }
  };

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="category">Category *</label>
          <Dropdown
            id="category"
            name="category"
            options={categoryOptions}
            value={form.category || ""}
            onChange={handleChange}
          />
          {errors.category && (
            <label className="p-error">{errors.category}</label>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="code">Item Code *</label>
          <InputText
            id="code"
            name="code"
            value={form.code || ""}
            onChange={handleChange}
            autoComplete="off"
          />
          {errors.code && <label className="p-error">{errors.code}</label>}
        </div>
        <div className="p-field">
          <label htmlFor="name">Item Name *</label>
          <InputText
            id="name"
            name="name"
            value={form.name || ""}
            onChange={handleChange}
            autoComplete="off"
          />
          {errors.name && <label className="p-error">{errors.name}</label>}
        </div>
        <div className="p-field">
          <label htmlFor="description">Description</label>
          <InputTextarea
            id="description"
            name="description"
            rows={4}
            value={form.description || ""}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="p-field">
          <label htmlFor="measureUnit">Measure Unit *</label>
          <InputText
            id="measureUnit"
            name="measureUnit"
            value={form.measureUnit || ""}
            onChange={handleChange}
            autoComplete="off"
          />
          {errors.measureUnit && (
            <label className="p-error">{errors.measureUnit}</label>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="reOrderLevel">Reorder Level</label>
          <InputText
            id="reOrderLevel"
            name="reOrderLevel"
            value={form.reOrderLevel || ""}
            onChange={handleChange}
            autoComplete="off"
          />
          {errors.reOrderLevel && (
            <label className="p-error">{errors.reOrderLevel}</label>
          )}
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onCancel}
          />
          <Button type="submit" label="Submit" />
        </div>
      </form>
    </div>
  );
};

export default ItemDialog;
