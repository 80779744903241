import React, { useState, useEffect } from "react";
import axios from "axios";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

const AddRemark = ({ visible, orderItem, onChange, onHide, toastrRef }) => {
  const [remark, setRemark] = useState("");

  useEffect(() => {
    const array = orderItem?.remark?.split(": Created") ?? [];
    setRemark(array[0] ?? "");
  }, [orderItem]);

  const addRemark = async () => {
    try {
      await axios.post(`/api/remark`, {
        ...orderItem,
        remark: remark,
      });

      onChange();
    } catch (error) {
      console.error(error);
      toastrRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while getting data. Please try again.",
        life: 3000,
      });
    }
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      style={{ width: "450px" }}
      header="Add Remark"
      modal
      draggable={false}
    >
      <div className="p-fluid">
        <InputTextarea
          rows={5}
          cols={55}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          autoResize
        />
      </div>
      <div className="flex justify-content-end mt-4">
        <Button
          label="Cancel"
          type="button"
          onClick={onHide}
          className="p-button-text"
        />
        <Button label="Submit" onClick={addRemark} />
      </div>
    </Dialog>
  );
};

export default AddRemark;
