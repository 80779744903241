import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import GoodsReceiveDialog from "../../components/GoodReceiveDialog";
import {
  currencyAmount,
  DateOnlyFormat,
} from "../../../helpers/TemplateHelper";

const GoodsReceive = () => {
  const toast = useRef(null);
  const dt = useRef(null);

  const [goods, setGoods] = useState([]);
  const [selectedGoods, setSelectedGoods] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [height, setHeight] = useState(window.innerHeight - 320);
  const [userRole, setUserRole] = useState(null);

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  useEffect(() => {
    fetchGoods();

    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const role = decodedPayload.role;
      setUserRole(role);
    }

    const handleResize = () => {
      setHeight(window.innerHeight - 320);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchGoods = async () => {
    try {
      const response = await axios.get("/api/GoodReceives");
      setGoods(response.data);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch goods. Please try again.",
        life: 3000,
      });
    }
  };

  const editGoods = (rowData = null) => {
    setSelectedGoods(rowData);
    setDialogVisible(true);
  };

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const statusTemplate = (rowData) => {
    const handleStatus = (isEnabled) => {
      confirmDialog({
        message: `Are you sure you want to ${
          isEnabled ? "activate" : "deactivate"
        } this item?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => confirmStatus(rowData, isEnabled),
      });
    };
    return (
      <>
        {userRole === "admin" && (
          <div className="p-field-checkbox">
            <Checkbox
              inputId={`statusCheckbox_${rowData.id}`}
              checked={rowData.status === 1}
              onChange={(e) => handleStatus(e.checked)}
              tooltip={rowData.status ? "Deactivate" : "Activate"}
            />
          </div>
        )}
      </>
    );
  };

  const confirmStatus = async (rowData, isEnabled) => {
    try {
      const updatedData = { ...rowData, status: isEnabled ? 1 : 0 };
      const response = await axios.post(`/api/GoodReceives`, updatedData);

      if (response.status === 200 || response.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${isEnabled ? "Activated" : "Deactivated"} successfully`,
          life: 3000,
        });

        const updatedIndex = goods.findIndex((item) => item.id === rowData.id);
        if (updatedIndex !== -1) {
          goods[updatedIndex] = updatedData;
          setGoods([...goods]);
        }
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while changing status. Please try again.",
        life: 3000,
      });
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  return (
    <div className="card flex flex-column gap-4">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between align-items-center">
        <div>
          <h4 className="text-primary mb-1">Goods Receive</h4>
          <label className="text-secondary">
            Add, edit, view received goods
          </label>
        </div>
        <div className="flex gap-2 align-items-center">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm"
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
            />
          </span>
          <Button
            label="Refresh"
            icon="pi pi-refresh"
            severity="help"
            onClick={fetchGoods}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            severity="success"
            onClick={exportCSV}
          />
          <Button
            label="Add Goods"
            icon="pi pi-plus"
            className="p-button-primary"
            onClick={() => editGoods(null)}
          />
        </div>
      </div>
      <DataTable
        ref={dt}
        value={goods}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        globalFilter={globalFilter}
        scrollable
        scrollHeight={height}
      >
        <Column field="item.code" header="Item Code" sortable />
        <Column field="item.name" header="Item Name" sortable />
        <Column
          field="quantity"
          header="Quantity"
          sortable
          className="text-right"
          body={(rowData) => `${currencyAmount(rowData.quantity)}`}
        />
        <Column
          field="unitPrice"
          header="Purchase Price"
          className="text-right"
          sortable
          body={(rowData) => `${currencyAmount(rowData.unitPrice)}`}
        />
        <Column
          field="sellingPrice"
          header="Selling Price"
          className="text-right"
          sortable
          body={(rowData) => `${currencyAmount(rowData.sellingPrice)}`}
        />
        <Column
          sortable
          field="updated"
          header="Updated"
          body={(row) => <DateOnlyFormat date={row.updated} />}
        />
        <Column sortable field="updatedBy" header="Updated By" />
        <Column field="status" header="Status" body={statusTemplate} sortable />
      </DataTable>
      <Dialog
        visible={dialogVisible}
        onHide={hideDialog}
        header="Goods Details"
        style={{ width: "500px" }}
        resizable={false}
      >
        <GoodsReceiveDialog
          goods={selectedGoods}
          onCancel={hideDialog}
          onSubmit={() => {
            hideDialog();
            fetchGoods();
          }}
        />
      </Dialog>
    </div>
  );
};

export default GoodsReceive;
