import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import ItemDialog from "../../components/ItemDialog";

const Items = () => {
  const toast = useRef(null);
  const dt = useRef(null);

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [height, setHeight] = useState(window.innerHeight - 320);
  const [userRole, setUserRole] = useState(null);

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  useEffect(() => {
    fetchItems();

    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const role = decodedPayload.role;
      setUserRole(role);
    }

    const handleResize = () => {
      setHeight(window.innerHeight - 320);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get("/api/items");
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items: ", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch items. Please try again.",
        life: 3000,
      });
    }
  };

  const editItem = (rowData = null) => {
    setSelectedItem(rowData);
    setDialogVisible(true);
  };

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const statusTemplate = (rowData) => {
    const handleStatus = (isEnabled) => {
      confirmDialog({
        message: `Are you sure you want to ${
          isEnabled ? "activate" : "deactivate"
        } this Item?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => confirmStatus(rowData, isEnabled),
      });
    };
    return (
      <React.Fragment>
        {userRole === "admin" && (
          <div className="p-field-checkbox">
            <Checkbox
              inputId={`statusCheckbox_${rowData.id}`}
              checked={rowData.status === 1}
              onChange={(e) => handleStatus(e.checked)}
              tooltip={rowData.status ? "Deactivate" : "Activate"}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-primary mr-2"
        tooltip="Edit"
        tooltipOptions={{ event: "both" }}
        onClick={() => editItem(rowData)}
      />
    );
  };

  const confirmStatus = async (rowData, status) => {
    try {
      const updatedData = { ...rowData, status: status ? 1 : 0 };
      console.log(updatedData);
      const response = await axios.post(`/api/items`, updatedData);

      if (response.status === 200 || response.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${status ? "activated" : "deactivated"} successfully`,
          life: 3000,
        });

        const updatedIndex = items.findIndex((item) => item.id === rowData.id);
        if (updatedIndex !== -1) {
          items[updatedIndex] = updatedData;
          setItems([...items]);
        }
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "An error occurred while enabling / disabling user. Please try again.",
        life: 3000,
      });
    }
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  return (
    <div className="card flex flex-column gap-4">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between align-items-center">
        <div>
          <h4 className="text-primary mb-1">Stock Items</h4>
          <label className="text-secondary">Add, edit, view stock items</label>
        </div>
        <div className="flex gap-2 align-items-center">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm"
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
            />
          </span>
          <Button
            severity="help"
            label="Refresh"
            icon="pi pi-refresh"
            onClick={fetchItems}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            severity="success"
            onClick={() => exportCSV(false)}
          />
          <Button
            label="Add Item"
            icon="pi pi-plus"
            className="p-button-primary"
            onClick={() => editItem(null)}
          />
        </div>
      </div>
      <DataTable
        ref={dt}
        value={items}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
        globalFilter={globalFilter}
        scrollable
        scrollHeight={height}
      >
        <Column
          body={actionTemplate}
          header={""}
          style={{ width: "50px" }}
        ></Column>
        <Column
          sortable
          field="id"
          header="Item Id"
          body={(row) => row.id.toString().padStart(8, "0")}
        />
        <Column field="category" header="Category" sortable />
        <Column field="code" header="Code" sortable />
        <Column field="name" header="Name" sortable />
        <Column field="description" header="Description" sortable />
        <Column field="measureUnit" header="Measure Unit" sortable />
        <Column
          field="reOrderLevel"
          header="Re-Order Level"
          style={{ textAlign: "right" }}
          sortable
        />
        <Column
          sortable
          field="updated"
          header="Updated Date"
          body={(row) => <span>{new Date(row.updated).toDateString()}</span>}
        />
        <Column sortable field="updatedBy" header="Updated By" />
        <Column header="Status" body={statusTemplate}></Column>
      </DataTable>
      <Dialog
        visible={dialogVisible}
        onHide={hideDialog}
        header="Item Details"
        style={{ width: "500px" }}
      >
        <ItemDialog
          item={selectedItem}
          onCancel={hideDialog}
          onSubmit={() => {
            hideDialog();
            fetchItems();
          }}
        />
      </Dialog>
    </div>
  );
};

export default Items;
