import React from "react";

const AppFooter = () => {
  return (
    <div className="layout-footer">
      <a className="text-color-secondary">
        Geeth Curtains Back Office v.1.0.3 © 2023, Geeth Curtains
      </a>
      <a
        className="caption text-color-secondary"
        href="http://www.elixirlk.com"
      >
        <strong>Elixir</strong> - Realizing Dreams through Enchanting
        Innovations !{" "}
      </a>
    </div>
  );
};

export default AppFooter;
