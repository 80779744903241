import React, { useEffect, useState, useRef } from "react";

import axios from "axios";

import printJS from "print-js";

import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

function PoleRequirementReport() {
  const printStyles = `
    body {font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; }
    label, div {font-size: 11px}
    strong {font-size: 10px}
    h4 {font-size: 13px}
    table, td, th {border: 0.5px groove ; font-size: 11px; padding: 4px} 
    table {width: 100%; border-collapse: collapse;}
    th {font-weight: 400; text-align: left, padding: 4px}
    .text-right {text-align: right}
    .text-left {text-align: left}
    .w-40 {max-width: 30px}
    .w-140 {max-width: 100px}`;

  const toast = useRef(null);
  const [order, setOrder] = useState(null);

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  const currentDate = new Date();

  function decodeJwt(token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const payload = JSON.parse(atob(parts[1]));
    return payload;
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedPayload = decodeJwt(token);
      const firstName = decodedPayload.firstName;
      const lastName = decodedPayload.lastName;
      setUserFirstName(firstName);
      setUserLastName(lastName);
    }
  }, []);

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = () => {
    axios
      .get("/api/reports/poles-report")
      .then((response) => {
        setOrder(response.data);
      })
      .catch((error) => {
        console.error("Error fetching order:", error);
        toast.current.show({
          severity: "error",
          summary: "Order Error",
          detail: "An error occurred while fetching order. Please try again.",
          life: 3000,
        });
      });
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  const formattedDate = `${formatDate(currentDate)} - ${formatTime(
    currentDate
  )}`;

  const handlePrint = () => {
    printJS({
      printable: "pole-requirement-report",
      type: "html",
      scanStyles: false,
      style: printStyles,
    });
  };

  return (
    <div className="container">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between">
        <div>
          <h4 className="text-primary mb-1">Pole Requirement Report</h4>
          <label className="text-secondary">
            View pole requirement report and print
          </label>
        </div>
        <div className="flex flex-row gap-2 flex-wrap h-1rem">
          <Button
            label="Refresh Data"
            severity="warning"
            onClick={() => fetchOrderData()}
          />
          <Button
            label="Print Report"
            icon="pi pi-print"
            onClick={handlePrint}
          />
        </div>
      </div>

      <div id="pole-requirement-report" style={{ fontSize: "11px !important" }}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="report-header">
                <h4>Pole Requirement Report</h4>
              </div>
              <div className="pt-4" style={{ paddingTop: "10px" }}>
                <div>Created: {formattedDate}</div>
                <div>
                  Created By: {userFirstName} {""} {userLastName}{" "}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingTop: "18px",
                }}
              >
                <img
                  src={require("../../../images/logo.png")}
                  alt="logo"
                  style={{ height: "50px" }}
                />
              </div>
              <div
                className="text-align-right text-right"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>RAGAMA - WALPOLA - WELIMADA - NUWARAELIYA</label>
                <label>Email : geethcurtains@gmail.com</label>
                <label>Phone : 0718021976 - 0771786299</label>
              </div>
            </div>
          </div>
        </div>
        {order?.map((order) => (
          <div key={order.id}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <strong>
                {order?.customerDetails?.fullName} (
                {order?.customerDetails?.telephone}) - Order:{" "}
                {order?.id.toString().padStart(8, "0")} - Date:{" "}
                {order?.created ? formatDate(order.created) : ""}
              </strong>
              <strong></strong>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <strong>Bill No: {order?.payments[0]?.referenceNo}</strong>
              <strong>Job Order No: </strong>
              <strong>Delivery Date:</strong>
            </div>
            <table className="order-table">
              <thead>
                <tr>
                  <th className="w-40" style={{ width: "40px" }}>
                    No
                  </th>
                  <th className="w-140" style={{ width: "140px" }}>
                    Window Type
                  </th>
                  <th style={{ width: "50px" }}>Height</th>
                  <th style={{ width: "90px" }}>Width</th>
                  <th style={{ width: "60px" }}>Pleats</th>
                  <th style={{ width: "40px" }}>Qty</th>
                  <th style={{ width: "90px" }}>Total Width</th>
                  <th style={{ width: "90px" }}>Total Pleats</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItems
                  ?.filter((item) => item.poleRequired)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.type}</td>
                      <td className="w-40 text-right">{item.height}</td>
                      <td className="text-right">{item.width} (Inches)</td>
                      <td className="text-right">{item.pleats}</td>
                      <td className="text-right">{item.quantity}</td>
                      <td className="text-right">
                        {item.width * item.quantity}
                      </td>
                      <td className="text-right">
                        {item.pleats * item.quantity}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  ))}
                <tr>
                  <td className="text-left" colSpan="5">
                    <strong>Total :</strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {order?.orderItems.reduce(
                        (totalQty, item) => totalQty + item.quantity,
                        0
                      )}
                    </strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {" "}
                      {order?.orderItems.reduce(
                        (totalQty, item) =>
                          totalQty + item.quantity * item.width,
                        0
                      )}
                    </strong>
                  </td>
                  <td className="text-right">
                    <strong>
                      {order?.orderItems.reduce(
                        (totalQty, item) =>
                          totalQty + item.quantity * item.pleats,
                        0
                      )}
                    </strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PoleRequirementReport;
