import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";

const ShortagesExcessesDialog = ({ onHide, onSubmit }) => {
  const [type, setType] = useState("Shortage");
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);
  const [unitPrice, setUnitPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [remark, setRemark] = useState("");

  const toast = useRef(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get("/api/items");
      setItems(
        response.data.map((item) => ({ label: item.name, value: item.id }))
      );
    } catch (error) {
      console.error("Error fetching items: ", error);
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!selectedItem) {
      newErrors.selectedItem = "Item selection is required.";
    }
    if (unitPrice <= 0) {
      newErrors.unitPrice = "Unit price must be greater than 0.";
    }
    if (quantity <= 0) {
      newErrors.quantity = "Quantity must be greater than 0.";
    }
    if (!remark) {
      newErrors.remark = "Remark is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        type,
        itemId: selectedItem,
        unitPrice,
        quantity,
        remark,
      };
      try {
        await axios.post("/api/shortagesExcesses", data);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Entry successfully recorded.",
          life: 3000,
        });
        onSubmit();
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Submission Error",
          detail: "Failed to submit the entry.",
          life: 3000,
        });
      }
    }
  };

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
      <form onSubmit={handleSubmit}>
        <div className="flex gap-4 mb-4">
          <div className="flex align-items-center gap-2">
            <RadioButton
              inputId="rb1"
              name="type"
              value="Shortage"
              onChange={(e) => setType(e.value)}
              checked={type === "Shortage"}
            />
            <label htmlFor="rb1">Shortage</label>
          </div>
          <div className="flex align-items-center gap-2">
            <RadioButton
              inputId="rb2"
              name="type"
              value="Excess"
              onChange={(e) => setType(e.value)}
              checked={type === "Excess"}
            />
            <label htmlFor="rb2">Excess</label>
          </div>
        </div>
        <div className="p-field">
          <label htmlFor="item">Item</label>
          <Dropdown
            id="item"
            value={selectedItem}
            options={items}
            onChange={(e) => setSelectedItem(e.value)}
            placeholder="Select Item"
          />
          {errors.selectedItem && (
            <small className="p-error">{errors.selectedItem}</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="unitPrice">Unit Price</label>
          <InputNumber
            id="unitPrice"
            value={unitPrice}
            onValueChange={(e) => setUnitPrice(e.value)}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
          {errors.unitPrice && (
            <small className="p-error">{errors.unitPrice}</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="quantity">Quantity</label>
          <InputNumber
            id="quantity"
            value={quantity}
            onValueChange={(e) => setQuantity(e.value)}
          />
          {errors.quantity && (
            <small className="p-error">{errors.quantity}</small>
          )}
        </div>
        <div className="p-field">
          <label htmlFor="remark">Remark</label>
          <InputTextarea
            id="remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            rows={3}
          />
          {errors.remark && <small className="p-error">{errors.remark}</small>}
        </div>
        <div className="flex justify-content-end">
          <Button
            type="button"
            label="Cancel"
            className="p-button-text mr-2"
            onClick={onHide}
          />
          <Button type="submit" label="Submit" className="p-button-primary" />
        </div>
      </form>
    </div>
  );
};

export default ShortagesExcessesDialog;
