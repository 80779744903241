import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import UpsertTodo from "../components/UpsertTodo";

function ToDo() {
  const toast = useRef(null);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [height, setHeight] = useState(0);

  const dt = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight;
      const specificAmount = 320;
      setHeight(vh - specificAmount);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/toDos");

      if (Array.isArray(response.data)) {
        setData(response.data);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while getting data. Please try again.",
        life: 3000,
      });
    }
  };

  const editNote = (rowData) => {
    setSelected(rowData);
    setDialogVisible(true);
  };

  const handleDelete = (rowData) => {
    confirmDialog({
      message: "Are you sure you want to delete this key value pair?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDelete(rowData),
    });
  };

  const confirmDelete = async (value) => {
    axios
      .delete(`/api/todos/${value.id}`)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "ToDo Note deleted successfully.",
          life: 3000,
        });
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting key value pair", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "An error occurred while deleting the ToDo Note. Please try again.",
          life: 3000,
        });
      });
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const handleSubmit = (e) => {
    setDialogVisible(false);
    if (e) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "ToDo Note saved successfully.",
        life: 3000,
      });
      fetchData();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while getting data. Please try again.",
        life: 3000,
      });
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="p-button-rounded p-button-info mr-2"
          icon="pi pi-pencil"
          tooltip="Edit"
          tooltipOptions={{ event: "both" }}
          onClick={() => {
            editNote(rowData);
          }}
        />
        <Button
          className="p-button-rounded p-button-danger"
          icon="pi pi-trash"
          onClick={() => handleDelete(rowData)}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="card flex flex-column gap-4">
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-between align-items-center">
        <div>
          <h4 className="text-primary mb-1">ToDo Notes</h4>
          <label className="text-secondary">Add, edit, view todo notes</label>
        </div>
        <div className="flex gap-2 align-items-center">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm"
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
            />
          </span>
          <Button
            label="Refresh"
            icon="pi pi-refresh"
            severity="help"
            onClick={fetchData}
          />
          <Button
            label="Export"
            icon="pi pi-file-excel"
            severity="success"
            onClick={() => exportCSV(false)}
          />
          <Button
            label="Add Todo Note"
            icon="pi pi-plus"
            className="p-button-primary p-mb-3"
            onClick={() => editNote(null)}
          />
        </div>
      </div>
      <Dialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        header="Add / Edit ToDo Note"
        style={{ width: "400px", minHeight: "fit-content" }}
        resizable="false"
      >
        <UpsertTodo
          note={selected}
          onCancel={() => setDialogVisible(false)}
          onSubmit={handleSubmit}
        />
      </Dialog>
      <DataTable
        ref={dt}
        value={data}
        paginator
        rows={25}
        rowsPerPageOptions={[25, 50, 100]}
        globalFilter={globalFilter}
        scrollable
        scrollHeight={height}
      >
        <Column style={{ width: "100px" }} body={actionTemplate}></Column>
        <Column
          sortable
          field="content"
          header="Content"
          style={{
            maxWidth: "300px",
            fontSize: "1.15rem",
            fontWeight: 500,
            padding: 5,
          }}
          body={(rowData) => (
            <span
              className="colored-row"
              style={{
                backgroundColor: JSON.parse(rowData.configuration)
                  .BackgroundColor,
              }}
            >
              {rowData.content}
            </span>
          )}
        />
        <Column
          header="Updated"
          style={{ width: "250px" }}
          body={(rowData) => (
            <span>{`On ${new Date(rowData.updated).toLocaleDateString()} by ${
              rowData.updatedByName
            }`}</span>
          )}
        ></Column>
      </DataTable>
    </div>
  );
}

export default ToDo;
