import React, { useState } from "react";

import axios from "axios";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";

import { currencyAmount, FormattedDate } from "../../../helpers/TemplateHelper";

import ContributionDialog from "./ContributionDialog";

const ContributionsTable = ({
  contributions,
  onChange,
  toastrRef,
  orderStatus,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleDelete = (contribution) => {
    confirmDialog({
      message: "Are you sure you want to delete this contribution?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDelete(contribution),
    });
  };

  const confirmDelete = async (contribution) => {
    axios
      .delete(`/api/Contributions/${contribution.id}`)
      .then((response) => {
        toastrRef.current.show({
          severity: "success",
          summary: "Contribution Deleted",
          detail: "Contribution has been successfully deleted.",
          life: 3000,
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error deleting contribution", error);
        toastrRef.current.show({
          severity: "error",
          summary: "Contribution Error",
          detail:
            "An error occurred while deleting the contribution. Please try again.",
          life: 3000,
        });
      });
  };

  const actionTemplate = (rowData) => {
    if (orderStatus === 1) {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => {
              setSelected(rowData);
              setDialogVisible(true);
            }}
          />
          <Button
            className="p-button-rounded p-button-danger"
            icon="pi pi-trash"
            onClick={() => handleDelete(rowData)}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="pt-8">
      <h5 className="text-color-secondary">Contributions</h5>
      <DataTable
        tableStyle={{ minWidth: "960px" }}
        value={contributions}
        className="p-datatable-striped"
      >
        <Column
          style={{ width: "100px" }}
          header=""
          body={actionTemplate}
        ></Column>
        <Column field="userName" header="Employee Name" sortable />
        <Column field="type" header="Type" sortable />
        <Column
          field="value"
          header="Value"
          sortable
          body={(rowData) => `${currencyAmount(rowData.value)} ${rowData.measureUnit.replace('per ', '')}(s)`}
        />
        <Column
          style={{ width: "200px" }}
          field="cost"
          header="Amount"
          headerStyle={{ textAlign: "right" }}
          bodyStyle={{ textAlign: "right" }}
          body={(rowData) => `${currencyAmount(rowData.cost)} LKR`}
          sortable
        />
        <Column
          style={{ width: "150px" }}
          field="updated"
          header="Updated"
          body={(row) => <FormattedDate date={row.updated} />}
          sortable
        />
        <Column
          style={{ width: "200px" }}
          field="updatedByName"
          header="Updated By"
          sortable
        />
      </DataTable>
      <ContributionDialog
        visible={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        contribution={selected}
        onContributionAdded={() => onChange()}
        toastrRef={toastrRef}
      />
    </div>
  );
};

export default ContributionsTable;
